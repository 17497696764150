import Axios from 'axios';
export default class Perfil {

    static getPerfiles(){
        return new Promise((resolve, reject)=>{
            Axios.get('/perfil').then((e)=>{
                return resolve( e.data.perfiles );
            }).catch((error)=>{
                return reject(error)
            })
        })
    }

}