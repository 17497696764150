<template>
    <div>
        <container-view titulo="Crear marca" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12 form-group"> 
                        <tool-tip :isInvalid="$v.marca.$invalid && send ? true : false" msj="Debe ingresar un nombre de marca"></tool-tip>   
                        <div class="form-floating">                            
                            <input v-model="marca.marca" type="text" class="form-control" id="marca" placeholder="Ingrese marca" :class="$v.marca.$invalid && send ? 'is-invalid' : ''">
                            <label for="marca" class="form-label">Ingrese marca</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-outline-danger me-3" @click="eliminar"><i class="fa fa-trash-can me-2"></i>Eliminar</button>
                    <button class="btn btn-primary" @click="guardar"><i class="fa fa-plus me-2"></i>Guardar</button>
                </div>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import Marca from './marca';
import { ref } from 'vue';
import ToolTip from '@/components/admin/template/ToolTip';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import Alert from '@/components/utils/alert'

const router = useRouter();
const route = useRoute();
const marca = ref({
    id: route.params.id
});

//--vuelidate
const rules = {
    marca: {required }
}
const send = ref(false);
const $v = useVuelidate(rules, marca);
//---

const getMarca = async()=>{
    await Marca.getMarca(marca.value.id).then((e)=>{
        marca.value = e.data.marca
    }).catch((e)=>{
        Alert.Error({
            html: `<span>No se pudo obtener datos de la marca.</span>
            </br><small> - ${e.response.data.message} -</small>`
        })
    })
}
getMarca();

const guardar = async()=>{
    send.value = true;
    if($v.value.$invalid)
        return false
    await Marca.update(marca.value).then(()=>{
        Alert.Success({text: 'Marca guardada con éxito'}).then(()=>{
            router.push({ name: 'ListarMarcas'})
        })
    }).catch((e)=>{
        Alert.Error({
            html: `<span>Ocurrió un error al guardar.</span>
            </br><small> - ${e.response.data.message} -</small>`
        })
    })
}

const eliminar = async()=>{
    await Marca.eliminar(marca.value.id).then(()=>{
        Alert.Success({text: 'Se a eliminado la marca con éxito'}).then(()=>{
            router.push({ name: 'ListarMarcas'})
        })
    }).catch((e)=>{
        Alert.Error({
            html: `<span>Ocurrió un error al eliminar.</span>
            </br><small> - ${e.response.data.message} -</small>`
        })
    })
}
</script>