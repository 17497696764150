<template>
<div>
    <Teleport to="head">
        <title>Tienda | OnlineSur | {{producto.nombre}}</title>
        <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
        <meta name="keywords" :content="'tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá, '+producto.nombre" />
        <meta property="og:type" content="website">
    </Teleport>
    <temp-top-bar>
        <template v-slot:usuario>
            <span v-if="useAuthStore.$state.usuario">Bienvenido {{useAuthStore.$state.usuario?.name}}</span>
        </template>
    </temp-top-bar>
    <div class="container-fluid">
        <div class="row border-top px-xl-5">
            <div class="col-3">
                <div class="col-12 d-none d-lg-block" style="position:absolute">
                    <temp-categories :isInitialOpen="false"></temp-categories>
                </div>
            </div>
            <div class="col-lg-9">
                <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                    <a href="" class="text-decoration-none d-block d-lg-none">
                        <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>                    
                    <temp-menu></temp-menu>
                </nav>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-secondary">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
            <h3 class="font-weight-semi-bold text-uppercase mb-3">Detalle de {{producto.nombre}}</h3>
        </div>
    </div>
    <div class="col-12 d-block d-sm-none px-0 my-4">
        <temp-categories :isInitialOpen="false" />
    </div>
    <div class="container-fluid py-5">
        <div class="row px-xl-5">
            <div class="col-lg-5 pb-5">
                <temp-carousel :isDetail="false" :images="producto.imagenes"></temp-carousel>
            </div>

            <div class="col-lg-7 pb-5">
                <h3 class="font-weight-semi-bold">{{producto.nombre}}</h3>
                <!-- <div class="d-flex mb-3">
                    <div class="text-primary mr-2">
                        <small class="fas fa-star"></small>
                        <small class="fas fa-star"></small>
                        <small class="fas fa-star"></small>
                        <small class="fas fa-star-half-alt"></small>
                        <small class="far fa-star"></small>
                    </div>
                    <small class="pt-1">(50 Reviews)</small>
                </div> -->
                <h3 class="font-weight-semi-bold mb-4" v-if="!producto.oferta">${{producto.precio}}</h3>
                <h3 class="font-weight-semi-bold mb-4" v-if="producto.oferta">${{producto.precio_oferta}}</h3>
                <p class="mb-4">{{producto.descripcion}}</p>
                <div class="d-flex mb-4" v-if="!mostrarSelectTalles">
                    <p class="text-dark font-weight-medium mb-0 mr-3">Colores:</p>                   
                    <div class="custom-control custom-radio custom-control-inline" v-for="(color, i) in producto.colores_talles" :key="i">
                        <input type="radio" class="custom-control-input radio" :style="'background-color:'+color.color" :id="color.color" :value="color.color" name="color" @click="obtenerCantidadPorColor()">
                        <label class="custom-control-label p-3" :for="color.color" :style="'background-color:'+color.color"></label>
                    </div>                   
                </div>
                <div v-if="mostrarSelectTalles" class="mb-4 px-2 row">
                    <div class="col-12 col-sm-6 px-0">
                        <select name="" id="" class="form-control" v-model="talleId">
                            <option :value="null">Seleccione un talle</option>
                            <template v-for="(talles, index) in gropupByTalles">
                                <option :value="talles.talles.id" v-if="talles.talles" :key="index">{{talles.talles?.talle}}</option>
                            </template>
                        </select>
                    </div>
                    <div class="d-flex mb-4 pt-3" v-if="filtroColorPorTalle.length > 0">
                        <p class="text-dark font-weight-medium mb-0 mr-3">Colores:</p>                   
                        <div class="custom-control custom-radio custom-control-inline" v-for="(color, i) in filtroColorPorTalle" :key="i">
                            <input type="radio" class="custom-control-input radio" :style="'background-color:'+color.color" :id="color.color" :value="color.color" name="color" @click="obtenerCantidadPorColor()">
                            <label class="custom-control-label p-3" :for="color.color" :style="'background-color:'+color.color"></label>
                        </div>                   
                    </div>
                </div>
                <div class="d-flex align-items-center mb-4 pt-2 px-2 row" v-if="useAuthStore.$state.isLoggin">
                    <div class="input-group quantity mr-sm-3 px-0 col-12 col-sm-4 mb-4 mb-sm-0">
                        <div class="input-group-btn">
                            <button id="restarProducto" class="btn btn-primary btn-minus" @click="restarProducto">
                            <i class="fa fa-minus"></i>
                            </button>
                        </div>
                        <input type="number" v-model="pedido.cantidad" id="cantidad" class="form-control bg-secondary text-center" :max="cantidadPorColor">
                        <div class="input-group-btn">
                            <button id="sumarProducto" class="btn btn-primary btn-plus" @click="sumarProducto">
                                <i class="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-auto px-0" v-if="cantidadPorColor > 0">
                        <button class="btn btn-primary px-3 col-12 col-sm-auto" @click="addToCart"><i class="fa fa-shopping-cart mr-1"></i>Agregar al Carrito</button>
                    </div>
                    <div v-else class="text-danger col-12 col-sm-6">
                        Disculpe, actualmente nos encontramos sin stock para el producto con estas caracteristicas.
                    </div>
                </div>
                <div v-else>
                    Debe ingresar al sistema para poder agregar al carrito. <a class="text-decoration-none" role="button" @click="irLogin">Página de inicio de sesión. </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import TempTopBar from '@/components/template/TempTopBar'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import TempCarousel from '@/components/template/TempCarousel'
import { UseAuthStore } from '@/store/authStore'
import Axios from 'axios'
import { ref, watchEffect, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'
import sound from '@/components/utils/sound'
import { UseCarritoStore } from '@/store/carritoStore'
const useAuthStore = UseAuthStore();
const useCarritoStore = UseCarritoStore();
const route = useRoute();
const router = useRouter();

const pedido = ref({
    cantidad: 1
})
const cantidadPorColor = ref(1)
const producto = ref({
    id: route.params.id
})
const irLogin=()=>{
    router.push({name:"Login"})
}
const getProducto = async()=>{
    await Axios.get('nuestros-productos/'+producto.value.id)
    .then(e => producto.value = e.data.producto)
    .catch(()=> Alert.Error({text: 'No se pudo encontrar el producto'}).then(()=> router.go(-1) ))
}
getProducto()

const gropupByTalles = computed(()=>{
    return producto.value.colores_talles.filter((a, b, c)=> c.findIndex(t => (t.talle_id===a.talle_id ))===b )
})

const talleId = ref(null)
const filtroColorPorTalle = computed(()=>{
    if(producto.value.colores_talles)
        return producto.value.colores_talles.filter((e)=> e.talle_id == talleId.value)
    else
        return null
})
const mostrarSelectTalles = computed(()=>{
    if(!producto.value.colores_talles)
        return false;
    if(producto.value.colores_talles.find((e)=> e.talles)){
        return true
    }
    return false
})

const addToCart = ()=>{
    if(!document.querySelector(".radio:checked")){
        Alert.Error({ text: 'Por favor, seleccione un color.'})
        return false
    }
    try{
        let color = document.querySelector(".radio:checked").value
        useCarritoStore.agregarCarrito(producto.value, pedido.value.cantidad, color).then(()=>{
            if(localStorage.getItem('audio') == 'on')
                sound.Success();
            Alert.Success({text: 'El producto fue agregado a su carrito'})
        }).catch( error => Alert.Error({ text: error}))
    }catch(error){
        Alert.Error({ text: 'No se pudo agregar producto. '})
    }
}

onMounted(()=>{
    watchEffect(()=>{
        console.log(pedido.value.cantidad)
        if(pedido.value.cantidad > 1){
            console.log("asd")
            // document.getElementById("restarProducto").disabled = false
        }
    })
})

const sumarProducto = ()=>{
    if(cantidadPorColor.value <= pedido.value.cantidad){
        // event.target.disabled = true
        return false
    } 
    pedido.value.cantidad++
}
const restarProducto = ()=>{
    if(pedido.value.cantidad <= 1){
        // event.target.disabled = true
        return false
    } 
    document.getElementById("sumarProducto").disabled = false
    pedido.value.cantidad--
}

const obtenerCantidadPorColor = async()=>{
    if(document.querySelector(".radio:checked")){
        pedido.value.cantidad = 1
        let color = document.querySelector(".radio:checked").value
        cantidadPorColor.value = await useCarritoStore.getCantidadProductoPorColor(producto.value, color)
    }
}

</script>