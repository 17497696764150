<template>
    <div data-bs-target="#navbarCollapse" class="collapse navbar-collapse justify-content-between font-size-18" id="navbarCollapse">
        <div class="navbar-nav mr-auto py-0">
            <router-link class="nav-item nav-link active" :to="{name: 'Inicio'}">Inicio</router-link>
            <router-link class="nav-item nav-link" :to="{name: 'ListProductos'}" >Productos</router-link>
            <router-link class="nav-item nav-link" :to="{name: 'ListarPedidosCliente'}" v-if="useAuthStore.isLoggin">Mis pedidos</router-link>
            <a href="contact.html" class="nav-item nav-link">Contacto</a>
        </div>
        <div class="navbar-nav ml-auto py-0">
            <router-link class="nav-item nav-link" :to="{name: 'Login'}" v-if="!useAuthStore.$state.isLoggin">Ingresar</router-link>
            <router-link class="nav-item nav-link" :to="{name: 'VerCliente'}" v-if="useAuthStore.isLoggin">Mi Perfil</router-link>
            <a role="button" class="nav-item nav-link" @click="logout()" v-if="useAuthStore.$state.isLoggin">Cerrar Session</a>
            <router-link class="nav-item nav-link" :to="{name: 'Registrar'}" v-if="!useAuthStore.$state.isLoggin">Registrarme</router-link>
        </div>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import { UseAuthStore } from '@/store/authStore'
import { useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'
import { UseCarritoStore } from '@/store/carritoStore'
const useCarritoStore = UseCarritoStore();
const useAuthStore = UseAuthStore();
const router = useRouter()

const logout = ()=>{
    if(useCarritoStore.storage.length > 0 ){
        Alert.Option({ 
            text: 'Si cierra su session, se perderan los productos agregados al carrito',
            confirmButtonText: 'Cerrar de todos modos'
        }).then((respuesta)=>{
            if(respuesta.isConfirmed){
                useAuthStore.logout().then(()=>{
                    Alert.Success({
                        titles: 'A cerrado su session',
                        text: 'Gracias por su visita. Hasta pronto.'
                    }).then(()=>{
                        router.push({name: 'Inicio'})
                    })
                })
            }
        })
    }else{
        useAuthStore.logout().then(()=>{
            Alert.Success({
                titles: 'A cerrado su session',
                text: 'Gracias por su visita. Hasta pronto.'
            }).then(()=>{
                router.push({name: 'Inicio'})
            })
        })
    }
}
</script>