<template>
    <div>
        <container-view titulo="Lista de Categorías" classCard="bg-white" :isCard="true">
            <template v-slot:top>
                <div class="d-flex col-12 justify-content-end mb-4">
                    
                    <button class="btn btn-primary  col-12 col-sm-auto" @click="agregarCategoria()"><i class="fa fa-plus me-2"></i>Nueva Categoría</button>

                </div>
            </template>
            <template v-slot:body>
                <div class="container-fluid">
                    <div class="row justify-content-end">
                        
                        <div class="col-md-3 col-lg-3 col-sm-12">

                        </div>

                    </div>

                    <div class="row  my-1 border-bottom py-2 mt-2 justify-content-md-center" v-for="(categoria, i) in categorias" :key="i">
                       <div class="col-md-3 col-lg-4 col-sm-12">
                           <span>Descripción: <b>{{categoria.descripcion}}</b></span>
                       </div>
                       <div class="col-md-5 col-lg-4 col-sm-12">
                           <span>SubCategorías: </span>
                           <div class="row hovered  border-bottom  ml-2" v-for="(subCategoria, i) in categoria.sub_categorias" :key="i">
                               <div class="col-md-7 col-sm- col-lg-7 col-7 pt-2 ">
                                    <span class="">{{subCategoria.descripcion}} </span>
                               </div>
                               <div class="col-lg-4 col-sm-4 col-md-4 col-5 row ">
                                   
                                    <div class="col-4 text-center">
                                        <button class=" btn text-success" @click="editarSubCategoria(subCategoria)">
                                                <i class="fa fa-edit"></i>
                                        </button>
                                    </div>
                                    <div class="col-4 text-center">
                                            <button class="btn text-danger" @click="eliminarSubCategoria(subCategoria)"><i class="fa fa-times"></i></button>
                                    </div> 
                                    
                               </div>
                           </div>
                       </div>
                       
                       <div class="col-lg-3 col-sm-12 " style="margin:0 auto;">
                            <div class="justify-content-around row ">
                                <button class="col-12 col-sm-12 col-lg-5 btn btn-outline-success mt-1 " @click="editarCategoria(categoria)">Editar</button>
                                <button class="col-12 col-sm-12 col-lg-5 btn btn-outline-danger mt-1  " @click="eliminarCategoria(categoria)">Eliminar</button>
                            </div>
                            <div class=" ">
                                <button class="col-12 col-sm-12 btn btn-outline-primary mt-1 " @click="agregarSubCategoria(categoria)"><i class="fa fa-plus"></i> SubCategoría</button>
                            </div>
                       </div>
                   </div>
                </div>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import {onMounted, ref } from 'vue'
import Categoria from '@/components/admin/categoria/categoria'
import Swal from 'sweetalert2';


const categorias = ref([]);
onMounted(async()=>{
    categorias.value = await Categoria.getCategorias();
});
const agregarCategoria = ()=>{
    Swal.fire({
        title:'Creando categoría',
        showCancelButton:true,
        html:'<input type="text" class="form-control" name="createCategoria" id=createCategoria value=""/>'
    }).then(async(result)=>{
        if(result.isConfirmed){
            let nuevaCategoria = document.getElementById("createCategoria").value;
            if(nuevaCategoria != ''){
                let res = await Categoria.createCategoria(nuevaCategoria);
                
                if(res.status == 200){
                    Swal.fire('Exito', 'Se creó la nueva categría', 'success');
                    categorias.value = await Categoria.getCategorias();
                }else{
                    Swal.fire('Error', 'Ocurrio un error al crear la categoría', 'error');
                }
            }
        }
    })
}
const agregarSubCategoria = (categoria)=>{
    Swal.fire({
        title:'Creando subcategoría en '+categoria.descripcion,
        showCancelButton:true,
        html:'<input type="text" class="form-control" name="createCategoria" id=createCategoria value=""/>'
    }).then(async(result)=>{
        if(result.isConfirmed){
            let descripcion = document.getElementById("createCategoria").value;
            if(descripcion != ''){
                let res = await Categoria.createSubCategoria(descripcion, categoria );
                if(res.status == 200){
                    Swal.fire('Exito', 'Se creó la nueva categría', 'success');
                    categorias.value = await Categoria.getCategorias();
                }else{
                    Swal.fire('Error', 'Ocurrio un error al crear la categoría', 'error');
                }
            }
        }
    })
}
const eliminarCategoria = (categoria)=>{
    Swal.fire({
        showCancelButton:true,
        icon:'warning',
        text:'Está seguro de eliminar la categoría '+categoria.descripcion+'? Tambien se eliminaran las subcategorías que dependen de ella.'
    }).then(async(result)=>{
        if(result.isConfirmed){
            let res = await Categoria.deleteCategoria(categoria);
            categorias.value = await Categoria.getCategorias();
            if(res.data == 200){
                Swal.fire('Exito', 'Se guardaron los cambios con éxtio', 'success');
            }else{
                Swal.fire('Error', 'Ocurrio un error al eliminar la categoría', 'error');
            }
        }
    })
}
const eliminarSubCategoria = (subcategoria)=>{
    Swal.fire({
        showCancelButton:true,
        icon:'warning',
        text:'Está seguro de eliminar la subcategoría '+subcategoria.descripcion+'?'
    }).then(async(result)=>{
        if(result.isConfirmed){
            let res = await Categoria.deleteSubCategoria(subcategoria);
            categorias.value = await Categoria.getCategorias();
            if(res.data == 200){
                Swal.fire('Exito', 'Se guardaron los cambios con éxtio', 'success');
            }else{
                Swal.fire('Error', 'Ocurrio un error al eliminar la categoría', 'error');
            }
        }
    })
}
const editarCategoria = (categoria=>{
    Swal.fire({
        title:'Modificando categoría',
        showCancelButton:true,
        html:'<input type="text" class="form-control" name="descripcionCategoria" id=descripcionCategoria value="'+categoria.descripcion+'"/>'
    }).then(async(result)=>{
        if(result.isConfirmed){
            categoria.descripcion = document.getElementById("descripcionCategoria").value;
            let res = await Categoria.editCategoria(categoria);
            if(res.status == 200){
                Swal.fire('Exito', 'Se guardaron los cambios con éxtio', 'success');
            }else{
                Swal.fire('Error', 'Ocurrio un error al modificar la categoría', 'error');
                categorias.value = await Categoria.getCategorias();
            }
        } 
        
    })
})
const editarSubCategoria = (subcategoria)=>{
    console.log("subcate", subcategoria)
    Swal.fire({
        title:'Modificando subcategoría',
        showCancelButton:true,
        html:'<input type="text" class="form-control" name="descripcionCategoria" id=descripcionCategoria value="'+subcategoria.descripcion+'"/>'
    }).then(async(result)=>{
        if(result.isConfirmed){
            subcategoria.descripcion = document.getElementById("descripcionCategoria").value;
            let res = await Categoria.editSubCategoria(subcategoria);
            if(res.status == 200){
                Swal.fire('Exito', 'Se guardaron los cambios con éxtio', 'success');
            }else{
                Swal.fire('Error', 'Ocurrio un error al modificar la categoría', 'error');
                categorias.value = await Categoria.getCategorias();
            }
        } 
        
    })
}

// usuario = useAuthStore.usuario
</script>
