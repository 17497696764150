<template>
    <container-view titulo="Lista de productos" classCard="bg-white" :isCard="true">
        <template v-slot:top>
            <div class="d-flex col-12 justify-content-end mb-4">
                <router-link :to="{name: 'CreateProducto'}" class="btn btn-primary col-12 col-sm-auto"><i class="fa fa-plus me-2"></i>Agregar Producto</router-link>
            </div>
        </template>
        <template v-slot:body>
            <data-list :fields="fields" :datas="productos" @evento="opciones($event)"></data-list>
        </template>
    </container-view>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import Productos from './producto'
import { ref, watchEffect } from 'vue'
import Alert from '@/components/utils/alert'
import DataList from '@/components/admin/template/DataList'
import { useRouter } from 'vue-router'
import Fecha from '@/components/utils/fecha'
const router = useRouter();
const fields = [
    {
        titulo: 'Nombre:',
        key: 'nombre',
        clase: 'text-primary',
        claseTitulo: "text-success"
    },
    {
        titulo: 'Categoria',
        key: 'categoria.descripcion',
    },
    {
        titulo: 'Sub Categoria',
        key: 'subcategorias.descripcion',
    },
    {
        titulo: 'Marca',
        key: 'marca.marca',
    },
    {
        titulo: 'Precio',
        key: 'precio',
        append: '$'
    },
    {
        titulo: 'Oferta',
        key: 'oferta',
    },
    {
        titulo: 'Creado:',
        key: 'created_at',
    }
]

const productos = ref([])

const getProductos = async()=>{
    await Productos.getProductos().then((e)=>{
        productos.value = e.data.productos
    }).catch((e)=>{
        Alert.Error({
            html: `<span>No se pudieron obtener productos.</span>
            </br><small> - ${e.response.data.message} -</small>`
        })
    })
}
getProductos();
watchEffect(()=>{
    productos.value.filter((e)=> e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY"))
})


const opciones = (producto)=>{
    // Alert.Option({
    //         titles:"Seleccione una opción", 
    //         text:"", 
    //         icon:"", 
    //         cancelButtonText:"Ver imagenes", 
    //         confirmButtonColor:"#0d6efd",
    //         confirmButtonText:"Ver Producto",
    //     }).then((e)=>{
    //         if(e.isConfirmed){
    //             router.push({ name: 'VerProducto', params:{ id: producto.id }})
    //         }
    //     })
    router.push({ name: 'VerProducto', params:{ id: producto.id }})
}

</script>