<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur | Mi carrito</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá, productos" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar>
            <template v-slot:usuario>
                <span v-if="useAuthStore.$state.usuario">Bienvenido {{useAuthStore.$state.usuario?.name}}</span>
            </template>
        </temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>                    
                        <temp-menu></temp-menu>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Mi carrito</h3>
            </div>
        </div>
        <div class="container-fluid pt-sm-5 row col-12">
            <div class="col-12 d-sm-none">
                <div class="bg-secondary py-2">
                    <h6 class="ms-2 pt-2" translate="no">Verifique su lista: </h6>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-8">
                <table class="table table-bordered text-center mb-0 tabla-responsiva-carrito">
                    <thead class="bg-secondary text-dark">
                        <tr>
                            <th></th>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Color</th>
                            <th>Total</th>
                            <th>Quitar</th>
                        </tr>
                    </thead>
                    <tbody class="align-middle">
                        <tr v-for="(carrito, i) in useCarritoStore.storage" :key="i" :id="'p'+carrito.producto.id">
                            <td class="imagenes" style="width:130px;">
                                <div class="contenedor-img" v-if="carrito.producto.ruta">
                                    <img class="img-principal" :src="urlBack+'/'+carrito.producto.ruta" alt="">
                                    <img class="img-secundaria" :src="urlBack+'/'+carrito.producto.ruta" alt="">
                                </div>
                                <div class="contenedor-img" v-else>
                                    <img class="img-principal" :src="require('@/assets/no-image-1.png')" alt="">
                                    <img class="img-secundaria" :src="require('@/assets/no-image-1.png')" alt="">
                                </div>
                            </td>
                            <td class="producto">
                                <b>{{carrito.producto.nombre}}</b>
                            </td>
                            <td class="precio">
                                <span class="mr-2"><small>Precio: </small></span>
                                <div>
                                    <b>${{carrito.producto.precio}}</b>
                                </div>
                            </td>
                            <td class="cantidad">
                                <span class="mr-2"><small>Cantidad: </small></span>
                                <b>{{carrito.producto.cantidad_pedida}}</b>
                            </td>
                            <td class="color">
                                <span class="mr-2"><small>Color: </small></span>
                                <b class="px-sm-3 py-sm-1 px-3 py-2" :style="'background-color:'+carrito.producto.color"></b>
                            </td>
                            <td class="total">
                                <span class="mr-2"><small>Total: </small></span>
                                <div>
                                    <b>${{carrito.producto.total}}</b>
                                </div>
                            </td>
                            <td class="boton"><button class="btn btn-sm btn-primary" @click="quitarProducto(i)"><i class="fa fa-times"></i></button></td>
                        </tr>
                    </tbody>
                </table>
                <p v-if="useCarritoStore.storage.length == 0" class="text-center text-muted mt-4">No a agregado productos a su carrito</p>
            </div>
            <div class="col-sm-12 col-12 col-lg-4 mt-4 mt-sm-0">
                <div class="card border-secondary mb-5">
                    <div class="card-header bg-secondary border-0">
                        <h4 class="font-weight-semi-bold m-0">Resumen de compra</h4>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between mb-3 pt-1">
                            <h6 class="font-weight-medium">Subtotal</h6>
                            <h6 class="font-weight-medium">$150</h6>
                        </div>
                    </div>
                    <div class="card-footer border-secondary bg-transparent">
                        <div class="d-flex justify-content-between mt-2">
                            <h5 class="font-weight-bold">Total</h5>
                            <h5 class="font-weight-bold">${{totalCarrito}}</h5>
                        </div>
                        <button class="btn btn-block btn-primary my-3 py-3" @click="confirmarCompra">Realizar compra</button>
                        <button class="btn btn-block btn-primary my-3 py-3 d-none" @click="testeando">Buscar pedido</button>
                    </div>
                    <div id="mercadopago">
                        
                    </div>
                </div>
            </div>
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<style scoped src="@/assets/plantilla/tabla-responsiva-carrito.css"></style>
<style scoped>
.contenedor-img{
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    overflow: hidden;
}
</style>
<script setup>
import TempTopBar from '@/components/template/TempTopBar'
import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
// import TempProducts from '@/components/template/TempProducts'
import { UseAuthStore } from '@/store/authStore'
import { UseCarritoStore } from '@/store/carritoStore'
import Alert from '@/components/utils/alert'
import { computed } from 'vue'
import Axios from 'axios'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'

const route = useRoute()
const router = useRouter();
const useAuthStore = UseAuthStore()
const useCarritoStore = UseCarritoStore()
const urlBack = process.env.VUE_APP_URL.replace("/api", "")

const quitarProducto = (index)=>{
    const retorno = useCarritoStore.quitarProducto(index)
    if(retorno)
        Alert.Success({ text: 'Producto eliminado del carrito '})
    else
        Alert.Error({ text: 'No se pudo eliminar el producto '})
}
const totalCarrito = computed(()=>{
    return useCarritoStore.storage.reduce((acu, e)=> parseFloat(acu) + parseFloat(e.producto.total), 0).toFixed(2)
})

const confirmarCompra = ()=>{
    Axios.post('mercado-pago/preferencia', {pedido:useCarritoStore.storage, totalPedido: totalCarrito.value}).then((e)=>{
        if(e.data.productosSinStock.length > 0){
            let data = ""
            e.data.productosSinStock.map((p)=>{
                data +='<p>*'+p.nombreProducto+'</p>' 
            })
            Alert.Option({
            titles: 'Estimado cliente',
            html: `<span>Nos encontramos sin stock de los siguientes productos que no fueron agregados a su pedido:</span>
                </br><small> ${data} </small>`,
            confirmButtonColor: '#28a745',
            confirmButtonText: 'Realizar pago!',
            cancelButtonText: 'Cancelar'
            }).then((result)=>{
                if(result.isConfirmed){
                    window.location.href = e.data.init_point
                    // addCheckout(e.data.preference)
                }
            });
        }else
            window.location.href = e.data.init_point
            // addCheckout(e.data.preference)
    }).catch(()=>{
        Alert.Error({html: `<span>Se produjo un error al realizar el pedido</span>`})
    })  
}
/*
const addCheckout = async (preferencia_id)=>{
    const mp = new window.MercadoPago('TEST-8cd071a3-31b4-497e-acfa-67750f4308e0', {
        locale: 'es-AR'
    });
    mp.checkout({
    preference: {
        id: preferencia_id,
    },
    autoOpen: true, 
    });
    console.log(mp)
    const identificationTypes = await mp.getIdentificationTypes()
    console.log(identificationTypes)
}
*/
const scriptMp = document.createElement('script')
scriptMp.type = 'text/javascript';
scriptMp.src = 'https://sdk.mercadopago.com/js/v2'
document.body.appendChild(scriptMp)

// const buscar = ()=>{
//     if(!route.query.payment_id)
//         return false
//     Axios.post("/pedido", {paymentId: route.query.payment_id, pedido:useCarritoStore.storage, totalPedido: totalCarrito.value})
//     .then(()=>{
//         Alert.Success({titles:'¡Muchas gracias!' ,text: 'Su pago a sido confirmado.'}).then(()=>{
//             useCarritoStore.borrarCarrito();
//             router.push({ name: 'Inicio' })
//         })
//     }).catch(()=>{
//         Alert.Error({html: `<span>Se produjo un error al verificar su pago</span>`})
//     })
// }
// buscar();
const mensajeDePago = ()=>{
    if(route.query.status == "approved"){
        Alert.Success({titles:'¡Muchas gracias!' ,text: 'Su pago a sido confirmado.'}).then(()=>{
            useCarritoStore.borrarCarrito();
            router.push({ name: 'Inicio' })
        })
    }
    else if(route.query.status == "pending"){
        Alert.Success({titles:'¡Muchas gracias!' ,text: 'Su pago quedo pendiente. En cuanto se confirme le enviaremos su producto'}).then(()=>{
            useCarritoStore.borrarCarrito();
            router.push({ name: 'Inicio' })
        })
    }
    else if(route.query.status == "rejected"){
        Alert.Error({html: `<span>Hubo un error con su pago.</span></br>
        <span><small>Por favor vuelva a realizarlo o comuniquese con nosotros.</small></span>`})
    }
}
mensajeDePago();
const testeando = ()=>{
    let config = {
    // headers: {'Authorization': 'Bearer APP_USR-3631806230558594-051817-e46f4bb0f0b52149df10e69ef1dc21df-1126097404'},
    headers: {'Authorization': 'Bearer TEST-3631806230558594-051817-c46526b3615418c279d748b969e77131-1126097404'},
        params: {
            external_reference:'14'
        },
    }
    
    // Axios.get("https://api.mercadopago.com/authorized_payments/1248275885", config)
    Axios.get("https://api.mercadopago.com/v1/payments/search", config)
    .then((e)=>{
        console.log(e)
    }).catch(()=>{
        Alert.Error({html: `<span>Se produjo un error al verificar su pago</span>`})
    })
}
</script>