<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur | Mis datos</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá, productos" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar>
            <template v-slot:usuario>
                <span v-if="useAuthStore.$state.usuario">Bienvenido {{useAuthStore.$state.usuario?.name}}</span>
            </template>
        </temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>                    
                        <temp-menu></temp-menu>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Mis Datos</h3>
            </div>
        </div>
        <div class="row col-12 col-sm-8 mx-auto d-flex align-items-end">
            <div class="my-3">
                <span class="text-muted">La información personal proporcionada es meramente de uso privado y de logística, a fines de brindar un servicio de venta rápido y seguro para las compras realizadas en nuestro portal. Gracias por ser parte-</span>
            </div>
            <div class="mb-3 row align-items-end">
                <div class="col-sm-4 col-12">
                    <tool-tip :isInvalid="$v.apellido.$invalid && send ? true : false" msj="Por favor, ingrese su Apellido"></tool-tip>
                    <input type="text" v-model="user.apellido" class="form-control mb-2" id="apellido" placeholder="Apellido" required="required" data-validation-required-message="Por favor, ingrese su apellido" :class="$v.apellido.$invalid && send ? 'is-invalid' : ''"/> 
                </div>
                <div class="col-sm-4 col-12">
                    <tool-tip :isInvalid="$v.name.$invalid && send ? true : false" msj="Por favor, ingrese su nombre."></tool-tip>
                    <input type="text" v-model="user.name" class="form-control mb-2" id="nombre" placeholder="Nombre" required="required" data-validation-required-message="Por favor ingrese sus nombres" :class="$v.name.$invalid && send ? 'is-invalid' : ''"/> 
                </div>
                <div class="col-sm-4 col-12">
                    <tool-tip :isInvalid="$v.dni.$invalid && send ? true : false" msj="Por favor, ingrese su DNI."></tool-tip>
                    <input type="text" v-model="user.dni" class="form-control mb-2" id="dni" placeholder="DNI" required="required" data-validation-required-message="Por favor, ingrese su DNI" :class="$v.dni.$invalid && send ? 'is-invalid' : ''"/> 
                </div>
                <div class="col-12">
                    <tool-tip :isInvalid="$v.email.$invalid && send ? true : false" msj="Por favor, ingrese un email válido."></tool-tip>
                    <input type="email" v-model="user.email" class="form-control mb-2" id="email" placeholder="Email" required="required" :class="$v.email.$invalid && send ? 'is-invalid' : ''" /> 
                </div>
            </div>
            <div class="mb-3 row align-items-end">
                <div class="col-sm-6 col-12">
                    <tool-tip :isInvalid="$v.provincia.$invalid && send ? true : false" msj="Por favor, ingrese su Provincia."></tool-tip>
                    <select  class="form-control mb-2" v-model="user.provincia" placeholder="Provincia" id="provincia" :class="$v.provincia.$invalid && send ? 'is-invalid' : ''">
                        <option :value="null" :selected="user.provincia == null ? true : false"> - Seleccione provincia - </option>
                        <option v-for="(provincia, i ) in provincias" :key="i" :value="provincia.nombre" :selected="user.provincia == provincia.nombre ? true : false">{{provincia.nombre}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-12">
                    <tool-tip :isInvalid="$v.localidad.$invalid && send ? true : false" msj="Por favor, ingrese su Localidad."></tool-tip>
                    <select  class="form-control mb-2" v-model="user.localidad" placeholder="Provincia" id="localidad" :class="$v.localidad.$invalid && send ? 'is-invalid' : ''">
                        <option :value="''" :selected="user.localidad == '' ? true : false"> - Seleccione localidad - </option>
                        <option v-for="(localidad, i ) in localidades" :key="i" :value="localidad.nombre" :selected="user.localidad == localidad.nombre ? true : false">{{localidad.nombre}}</option>
                    </select>
                </div>
                <div class="col-12 d-flex align-items-end">
                    <div class="col-8 ps-0">
                        <tool-tip :isInvalid="$v.domicilio.$invalid && send ? true : false" msj="Por favor, ingrese su domicilio."></tool-tip>
                        <input type="text" v-model="user.domicilio" class="form-control mb-2" id="domicilio" placeholder="Domicilio" required="required" data-validation-required-message="Por favor, ingrese su domicilio" :class="$v.domicilio.$invalid && send ? 'is-invalid' : ''"/> 
                    </div>
                    <div class="col-4 pe-0">
                        <tool-tip :isInvalid="$v.cod_postal.$invalid && send ? true : false" msj="Por favor, ingrese su código postal."></tool-tip>
                        <input type="text" v-model="user.cod_postal" class="form-control mb-2" id="cod_postal" placeholder="Código postal" required="required" data-validation-required-message="Please enter your name" :class="$v.cod_postal.$invalid && send ? 'is-invalid' : ''"/> 
                    </div>
                </div>
                <div class="col-12">
                    <tool-tip :isInvalid="$v.telefono.$invalid && send ? true : false" msj="Por favor, ingrese su teléfono."></tool-tip>
                    <input type="text" v-model="user.telefono" class="form-control mb-2" id="telefono" placeholder="Telefono" required="required" :class="$v.telefono.$invalid && send ? 'is-invalid' : ''"/> 
                </div>
            </div>
            <!-- <div class="control-group">
                <tool-tip :isInvalid="$v.password.$invalid && send ? true : false" msj="Este campo es obligatorio"></tool-tip>
                <input type="password" class="form-control mb-2 border" id="password" v-model="user.password" placeholder="Contraseña" required="required" data-validation-required-message="Please enter your email" />
            </div>
            <div class="control-group">
                <tool-tip :isInvalid="$v.repetirPassword.confirmPassword.$invalid && send ? true : false" msj="Las contraseñas no coinciden"></tool-tip>
                <input type="password" class="form-control mb-2 border" id="repetirPassword" v-model="user.repetirPassword" placeholder="Repita su contraseña" required="required" data-validation-required-message="Please enter your email" />
            </div> -->
            <div class="text-right mt-4">
                <button type="button" class="btn btn-primary py-2 px-4" @click="updateData">Guardar cambios</button>
            </div>
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import TempTopBar from '@/components/template/TempTopBar'
import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import ToolTip from '@/components/admin/template/ToolTip'
import { UseAuthStore } from '@/store/authStore'
import { ref, watchEffect, computed } from 'vue';
import Cliente from '@/components/admin/cliente/cliente';
import Alert from '@/components/utils/alert'
import Axios from 'axios'
import { $v as validation } from '@/components/admin/usuario/validations'

const useAuthStore = UseAuthStore()
const user = ref({})
user.value = {...useAuthStore.usuario}
const send = ref(false);
const $v = validation(user.value)
const provincias = ref();

const updateData = ()=>{
    send.value = true
    if($v.value.$invalid)
        return false
    Alert.Option({ text: '¿Esta seguro que decea modificar sus datos ?', confirmButtonColor:"#0d6efd" }).then(async (res)=>{
        if(res.isConfirmed){
            await Cliente.update({...user.value}).then(()=>{
                Alert.Success({ text: 'Se modificó con éxito' })
            }).catch((e)=>{
                console.log(e)
                Alert.Error({ text: 'Ocurrió un error al modificar sus datos.' })
            })
        }
    })
}
const getProvincia = ()=>{
    Axios.get("https://api-localidades.mgutierrez.ar/provinces")
    .then((res) => {
        if (res.data) {
            provincias.value = res.data;
            provincias.value.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        }
    }).catch(() => {
        Alert.Error({text: `No se han podido obtener las provincias, intente más tarde.`});
    });
}
getProvincia();
const getLocalidades = (provinciaId)=>{
    return Axios.get("https://api-localidades.mgutierrez.ar/localidades?id="+provinciaId)
    .then((res) => {
        if (res.data) {
            let localidades = res.data;
            localidades.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
            return localidades
        }
    })
}
const finProvinciaId = computed(()=>{
    if(provincias.value){
        let provinciaId = provincias.value.find((e)=> e.nombre == user.value.provincia).id
        return provinciaId
    }
    return null
})
const localidades = ref({})
watchEffect(async ()=>{
    if(finProvinciaId.value){
        localidades.value = await getLocalidades(finProvinciaId.value)
    }
})

</script>