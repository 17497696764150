<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur | Login</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar></temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <temp-menu></temp-menu>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Iniciar Session</h3>
            </div>
        </div>
        <div class="container-fluid pt-5">
            <div class="text-center mb-4">
                <h2 class="section-title px-5"><span class="px-2">Ingrese sus datos</span></h2>
            </div>
            <div class="row px-xl-5">
                <form class="col-sm-7 col-12 mx-auto" name="sentMessage" id="contactForm" novalidate="novalidate">
                    <div class="control-group">
                        <tool-tip :isInvalid="$v.email.$invalid && send ? true : false" msj="Debe ingresar un email valido"></tool-tip>
                        <input type="email" v-model="user.email" class="form-control" id="email" placeholder="Email"
                            required="required" data-validation-required-message="Please enter your name" :class="$v.email.$invalid && send ? 'is-invalid' : ''" />
                        <p class="help-block text-danger" v-if="message?.error == 'usuario'">{{message.message}}</p>
                    </div>
                    <div class="control-group my-4">
                        <tool-tip :isInvalid="$v.password.$invalid && send ? true : false" msj="Debe ingresar su contraseña"></tool-tip>
                        <input @keyup.enter="login" type="password" class="form-control" id="password" v-model="user.password" placeholder="Contraseña"
                            required="required" data-validation-required-message="Please enter your email" :class="$v.password.$invalid && send ? 'is-invalid' : ''"/>
                        <p class="help-block text-danger" v-if="message?.error == 'password'">{{message.message}}</p>
                    </div>
                    <p class="text-danger" v-if="message?.noConfirmado">{{message.noConfirmado}}</p>
                    <div class="text-right d-flex d-sm-block flex-column flex-column-reverse">
                        <router-link class="btn col-12 col-sm-auto text-primary mr-0 mr-sm-4" :to="{ name: 'RecuperarContraseña'} ">¿Olvidaste tu contraseña?</router-link>
                        <button type="button" class="btn btn-primary py-2 px-4 col-12 col-sm-auto" @click="login">Ingresar</button>
                    </div>
                    <div class="text-right mt-2">
                        <!-- <button type="button" class="btn" @click="recuperarContraseña">Olvide mi contraseña</button> -->
                    </div>
                </form>
            </div>
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import TempTopBar from '@/components/template/TempTopBar'
import Axios from 'axios'
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { UseAuthStore } from '@/store/authStore'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ToolTip from '@/components/admin/template/ToolTip'
import Alert from '@/components/utils/alert'

const user = ref({})
const useAuthStore = UseAuthStore();

//--vuelidate
const rules = {
    email: { email, required },
    password: { required },
}
const send = ref(false);
const $v = useVuelidate(rules, user);
//---

useAuthStore.$reset();
sessionStorage.removeItem('token')
const message = ref();
const router = useRouter();
const route = useRoute();

const login = async ()=>{
    send.value = true;
    message.value = null;//seteo message a nulo, poqeu sino queda guardado en el store y no se loguea cuando te confundis de contraseña o marca algun error
    if($v.value.$invalid)
        return false
    send.value = false // la vuelvo a setear en false para que no genere elm error de campos faltantes
    await useAuthStore.login(user.value).then(async (e)=>{
        console.log("resultado login: ", e)
    }).catch((e)=>{
        message.value = e.response.data
        if(e.response.data.error == 'Cuenta bloqueada')
            Alert.Error({ title:"Lo sentimos...", html: '<b>Su cuenta se encuentra suspendida.</b> </br>Envienos un mensaje para obtener mas detalles.'})
    })
    if(message.value)
        return false
    await useAuthStore.getUserProfile().then((e)=>{
        user.value = e.data
    }).catch((e)=>{
        message.value = {
            noConfirmado: e.response.data.message
        }
        return null
    });
    if(!user.value.perfil){
        return false;
    }
    if(user.value.perfil.descripcion == "Cliente"){
        if(user.value.email_verified_at == null){
            await useAuthStore.logout().then(res =>{
                if(res.status == 200){
                    enviarEmailConfirmacion();
                }
            })
            return false   
        }
        router.push({ name: 'ListProductos'})
    }
    if(user.value.perfil.descripcion == "Administrador"){
        router.push({ name: 'InicioAdmin'})
    }
}

onMounted(()=>{
    if(route.query.action == 'verifiqued')
        Alert.Success({ text: 'Su cuenta a sido verificada. Ya puede ingresar y comernzar a usarla.'})
})

const enviarEmailConfirmacion = () =>{
     Alert.Option({
        icon: 'error',
        titles: 'Opps...',
        html: `<span><b>Hemos detectado que su cuenta no a sido confirmada.</b></span>
        </br><small> Posiblemente no ha confirmado su cuenta desde el enlace que enviamos a su correo eléctronico. </small>
        </br><small> ¿Desea que le volvamos a enviar la confirmacion? </small>`,
        confirmButtonText: 'Si reenviar!',
        confirmButtonColor: 'green'
    }).then((response)=>{
        if(response.isConfirmed){
            Axios.post('email/verification-notification', user.value)
            .then(()=>{
                Alert.Success({ 
                    html: `<span><b>Su correo fue enviado con éxito.</b></span></br><small> Recuerde verificar en la casilla de correos no deseados. </small>`
                })
            }).catch((error)=>{
                Alert.Error({
                    html: `<span>Ocurrió un error al enviar correo.</span>
                    </br><small> - ${error.response.data.message} -</small>`
                })
            })
        }
    })
}
</script>