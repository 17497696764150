<template>
    <div>
        <container-view titulo="Lista de clientes" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <data-list :fields="fields" :datas="clientes" @evento="verCliente($event)"></data-list>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import DataList from '@/components/admin/template/DataList'
import { ref, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import Clientes from './cliente'
import Fecha from '@/components/utils/fecha'
const fields = [
    {
        titulo: 'Nombre:',
        key: 'name',
        clase: 'text-primary',
        claseTitulo: "text-success"
    },
    {
        titulo: 'Email:',
        key: 'email',
    },
    {
        titulo: 'Creado:',
        key: 'created_at',
    }
]
const clientes = ref()
const router = useRouter()
onMounted(async()=>{
    await Clientes.getClientes().then( e => clientes.value = e )
    watchEffect(()=>{
        clientes.value.filter((e)=> e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY"))
    })
})

const verCliente = data => router.push({name: 'VerClienteAdmin', params: {id: data.id}})

</script>