import Axios from 'axios';
export default class Clientes {

    static getClientes(){
        return new Promise((resolve, reject)=>{
            Axios.get('/cliente').then((e)=>{
                return resolve( e.data.clientes );
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    
    static async getCliente(id){
        return new Promise((resolve, reject)=>{
            Axios.get('/cliente/'+id ).then((e)=>{
                return resolve( e.data.cliente );
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static async update(cliente){
        return new Promise((resolve, reject)=>{
            Axios.put('/cliente/'+cliente.id, cliente )
            .then( e => resolve( e ))
            .catch(error => reject(error) )
        })
    }
}