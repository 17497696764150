import Swal from 'sweetalert2';

export default class Alert{
    static Option({titles="¡Atención!", 
            text="", 
            icon="warning", 
            cancelButtonText="Cancelar", 
            cancelButtonColor="#343a40",
            confirmButtonText="Si, Estoy seguro",
            confirmButtonColor="#d33",
            html=''
            }){
        return Swal.fire({
            title: titles,
            text: text,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            html: html
        })
    }

    static Success({titles = "Exito...", html="",text="La operación se realizó con exito"}){
        return Swal.fire({
            title: titles,
            text:  text,
            html: html,
            icon:'success'
        })
    }
    static Error({text="Ocurrió un error", html='', title='Opsss...'}){
        return Swal.fire({
            title: title,
            text: text, 
            html: html,
            icon:'error'
        })
    }
}