import Axios from 'axios'

export class Talle{
    static getTalles(){
        return new Promise((resolve, reject)=>{
            Axios.get('talle').then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}