import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export const $v = data => useVuelidate(rules, data)

export const rules = {
    producto:{
        nombre: { required },
        descripcion: { required },
        precio: { required },
        categoria_id: { required }
    },
    color:{ required },
    cantidad:{ required }
}