<template>
   <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar></temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <temp-menu></temp-menu>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Recuperar Contraseña</h3>
            </div>
        </div>
        <div  class="container pt-1 col-sm-10 col-md-8 col-lg-6">            
            <p>Por favor ingresa la cuenta de correo registrada en nuestro sistemas y podremos enviarte un enlace para recuperar tu contraseña.</p>
            <div class="control-group text-right">
                <input type="email" v-model="user.email" class="form-control" id="email" placeholder="Email" required="required" data-validation-required-message="Por favor ingrese un email" />
                <p class="help-block text-danger" v-if="$v.email.$invalid && enviado ? true : false">Por favor ingrese un email válido</p>
                <button id="btnEnviar" class="btn btn-primary mt-2 col-12 col-sm-auto" @click="enviar">Enviar</button>
            </div>
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
 import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import TempTopBar from '@/components/template/TempTopBar'
import { ref } from '@vue/reactivity'
import axios from 'axios'
import Swal from 'sweetalert2'
import useVuelidate from '@vuelidate/core'
import { required, email } from "@vuelidate/validators";
import Alert from '@/components/utils/alert'

const enviado = ref(false)
const user = ref({ email:''})
const rules = {
    email:{required, email}    
}
const $v = useVuelidate(rules, user);
const enviar = ()=>{
    enviado.value = true;
    $v.value.$touch()
    if($v.value.$invalid){
        return false
    }
    axios.post('/forgot-password', user.value).then(res=>{
        if(res.status == 200){
            Swal.fire('Verifica tu correo','Hemos enviado un correo donde puedes continuar con la recuperación de tu contraseña', 'success')
            user.value.correo = '';
        }
    }).catch(()=>{
        Alert.Error({text: 'Ocurrió un error al intertar recuperar contraseña, por favor verifique su dirección de email.'}) 
    })
}
</script>