import Axios from 'axios';
export default class Color {
    static getColores(){
        return new Promise((resolve, reject)=>{
            Axios.get('color').then((e)=>{
                return resolve(e.data.color)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static createColor(color){
        return new Promise((resolve, reject)=>{
            Axios.post('color', color).then((e)=>{
                return resolve(e);
            }).catch((error)=>{
                return reject(error);
            })
        })
    }
    static deleteColor(id){
        return new Promise((resolve, reject)=>{
            Axios.delete('color/'+ id).then(e=>{
                return resolve(e);
            }).catch(error=>{
                return reject(error);
            })
        })
    }
}