import Axios from 'axios';
export default class Marca {
    static Create(marca){
        return new Promise((resolve, reject)=>{
            Axios.post('marca', marca).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getMarcas(){
        return new Promise((resolve, reject)=>{
            Axios.get('marca').then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getMarca(id){
        return new Promise((resolve, reject)=>{
            Axios.get('marca/'+id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static eliminar(id){
        return new Promise((resolve, reject)=>{
            Axios.delete('marca/'+id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static update(marca){
        return new Promise((resolve, reject)=>{
            Axios.put('marca/'+marca.id, marca).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}