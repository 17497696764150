<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar></temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <temp-menu></temp-menu>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Registrarme</h3>
            </div>
        </div>
            <div class="container-fluid pt-5">
                <div class="text-center mb-4">
                    <h2 class="section-title px-5"><span class="px-2">Registre sus datos</span></h2>
                </div>
                <div class="row px-xl-5">
                    <form class="col-sm-7 col-12 mx-auto" name="sentMessage" id="contactForm" novalidate="novalidate">
                    <div class="my-3">
                    <span class="text-muted">La información personal proporcionada es meramente de uso privado y de logística, a fines de brindar un servicio de venta rápido y seguro para las compras realizadas en nuestro portal. Gracias por ser parte-</span>
                    </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.apellido.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <input type="text" v-model="user.apellido" class="form-control mb-2 border" id="apellido" placeholder="Apellido"
                                required="required" data-validation-required-message="Please enter your name" /> 
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.name.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <input type="text" v-model="user.name" class="form-control mb-2 border" id="nombre" placeholder="Nombre"
                                required="required" data-validation-required-message="Please enter your name" /> 
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.dni.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <input type="text" v-model="user.dni" class="form-control mb-2 border" id="dni" placeholder="DNI"
                                required="required" data-validation-required-message="Please enter your name" /> 
                        </div>
                        <div class=" row">
                            <div class="col-8 control-group">
                            <tool-tip :isInvalid="$v.domicilio.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                                <input type="text" v-model="user.domicilio" class="form-control mb-2 border" id="domicilio" placeholder="Domicilio"
                                    required="required" data-validation-required-message="Please enter your name" /> 
                            </div>
                            <div class="col-4 control-group">
                            <tool-tip :isInvalid="$v.cod_postal.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                                <input type="text" v-model="user.cod_postal" class="form-control mb-2 border" id="cod_postal" placeholder="Código postal"
                                required="required" data-validation-required-message="Please enter your name" /> 
                            </div>
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.provincia.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <select  class="form-control mb-2 border" v-model="user.provincia" @change="obtenerLocalidades" placeholder="Provincia" id="provincia">
                                <option value=""> - Seleccione provincia - </option>
                                <option v-for="(provincia, i ) in provincias" :key="i" :value="provincia">{{provincia.nombre}}</option>
                            </select>
                            
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.localidad.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <select  class="form-control mb-2 border" v-model="user.localidad" placeholder="Provincia" id="localidad">
                                <option value=""> - Seleccione localidad - </option>
                                <option v-for="(localidad, i ) in localidades" :key="i" :value="localidad">{{localidad.nombre}}</option>
                            </select>
                            
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.telefono.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <input type="text" v-model="user.telefono" class="form-control mb-2 border" id="telefono" placeholder="2954252627"
                                required="required" data-validation-required-message="Please enter your name" /> 
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.email.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <input type="email" v-model="user.email" class="form-control mb-2 border" id="email" placeholder="Email"
                                required="required" data-validation-required-message="Please enter your name" /> 
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.password.$invalid && enviado ? true : false" msj="Este campo es obligatorio"></tool-tip>
                            <input type="password" class="form-control mb-2 border" id="password" v-model="user.password" placeholder="Contraseña"
                                required="required" data-validation-required-message="Please enter your email" />
                        </div>
                        <div class="control-group">
                            <tool-tip :isInvalid="$v.repetirPassword.confirmPassword.$invalid && enviado ? true : false" msj="Las contraseñas no coinciden"></tool-tip>
                            <input type="password" class="form-control mb-2 border" id="repetirPassword" v-model="user.repetirPassword" placeholder="Repita su contraseña"
                                required="required" data-validation-required-message="Please enter your email" />
                        </div>
                        <div class="text-right" v-if="captcha">
                            <button type="button" class="btn btn-primary py-2 px-4" @click="registrar">Registrarme</button>
                        </div>
                        <div v-else>
                            <p class="text-muted"><b>Por favor indique que no es un robot en la casilla de abajo y complete el ejercicio para poder registrarse.</b></p>
                        </div>
                        <re-captcha :siteKey="sitekey" @correct="captcha=$event"></re-captcha>
                    </form>
                </div>
            </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import ToolTip from '@/components/admin/template/ToolTip'

import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import TempTopBar from '@/components/template/TempTopBar'
import { onMounted, ref}  from 'vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, email } from "@vuelidate/validators";
import User from '@/components/admin/usuario/user';
import { useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'
import ReCaptcha from '@/components/template/ReCaptcha'
const router = useRouter();

const captcha = ref(); 
const sitekey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const enviado = ref(false);
const user = ref({
    password: null,
    repetirPassword:null
})
const provincias = ref([])
const localidades = ref([{id:'', nombre:''}])

const confirmPassword = (password) => password === user.value.password;
const rules = {      
    apellido: { required },
    name: { required },
    dni:{ required },
    domicilio: { required },
    localidad: { required },
    provincia: { required },
    cod_postal: { required },
    telefono: { required },
    email: { required, email},
    password: { required },
    repetirPassword: { required, confirmPassword: confirmPassword },
}
const $v = useVuelidate(rules, user);
onMounted(() => {
   obtenerProvincias(); 
});
const obtenerProvincias = ()=>{
    axios.get("https://api-localidades.mgutierrez.ar/provinces")
    // axios.get("https://apis.datos.gob.ar/georef/api/provincias")
    .then((res) => {
        if (res.data) {
            provincias.value = res.data;
            // provincias.value = res.data.provincias;
            provincias.value.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        }
    })
    .catch(() => {
        Alert.Error({text: 'No se han podido obtener las Provincias, intente más tarde.' })
    });
};
const obtenerLocalidades = ()=>{
    if(user.value.provincia.id != null){
        axios.get("https://api-localidades.mgutierrez.ar/localidades?id="+user.value.provincia.id)
        // axios.get("https://apis.datos.gob.ar/georef/api/municipios?provincia="+user.value.provincia.id+"&campos=id,nombre&max=100")
        .then((res) => {
            if (res.data) {
                // localidades.value = res.data.municipios;
                localidades.value = res.data;
                localidades.value.sort(function (a, b) {
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    return 0;
                });    
            }
        })
        .catch(() => Alert.Error({text: 'No se han podido obtener las localidades, intente más tarde.' }));
    }
}
const registrar = async ()=>{
    enviado.value = true;
    $v.value.$touch()
    if($v.value.$invalid)        
        return false

    await User.register(user.value).then((e)=>{
        if(e.status == 200){
            router.push({name: 'VerificarCorreo'})
        }
    }).catch((error) =>{
        if(error.response.data.error == 1){
            Alert.Error({
                html: `<span>Ocurrió un error al intentar registrarse.</span>
                </br><small> - ${error.response.data.message} -</small>
                </br><small> - Pruebe intentando recuperar su contraseña para poder ingresar nuevamente. -</small>`
            })
        }
        else if(error.response.data.error == 2){
            Alert.Option({
                icon: 'error',
                titles: 'Opps...',
                html: `<span><b>Ya existe un usuario con este email.</b></span>
                </br><small> Posiblemente no haya confirmado su cuenta desde su correo eléctronico. </small>
                </br><small> ¿Desea reenviar una confirmación a su correo? </small>`,
                confirmButtonText: 'Si reenviar!',
                confirmButtonColor: 'green'
            }).then((response)=>{
                if(response.isConfirmed){
                    axios.post('email/verification-notification', user.value)
                    .then(()=>{
                        Alert.Success({ 
                            html: `<span><b>Su correo fue enviado con éxito.</b></span></br><small> Recuerde verificar en la casilla de correos no deseados. </small>`
                        })
                    }).catch((error)=>{
                        Alert.Error({
                            html: `<span>Ocurrió un error al enviar correo.</span>
                            </br><small> - ${error.response.data.message} -</small>`
                        })
                    })
                }
            })
        }else{
            Alert.Error({
                html: `<span>Ocurrió un error al intentar registrarse.</span>
                </br><small> - ${error.response.data.message} -</small>`
            })
        }
    });
}
</script>