import Axios from 'axios'
export default class Pedidos{
    static async getPedido(id){
        return await Axios.get('pedido/'+id)
        .then( e => e.data )
        .catch( error => error )
    }
    static async getAllPedidos(page){
        return await Axios.get('pedido', {params: {page: page, paginate:true}}).then((e)=>{
            return e.data
        }).catch((error)=>{
            return error
        })
    }
    static async getPedidoByCliente(id, page){
        return await Axios.get('pedido', {params: {page: page, idCliente: id, paginate:true}}).then((e)=>{
            console.log(e.data)
            return e.data
        }).catch((error)=>{
            return error
        })
    }
    static async setPedidoEnviado(pedidoId){
        return new Promise((resolve, reject)=>{
            Axios.put('pedido/'+pedidoId).then((e)=>{
                return resolve( e.data );
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}