<template>
    <div>
        <container-view titulo="Lista de Colores" classCard="bg-white" :isCard="true">
            <template v-slot:top>
                <div class="d-flex col-12 justify-content-end mb-4">
                    <router-link :to="{name: 'CrearColor'}" class="btn btn-primary col-12 col-sm-auto"><i class="fa fa-plus me-2"></i>Agregar Color</router-link>
                </div>
            </template>
            <template v-slot:body>
                <div class="row">
                    <div class="tabla" v-if="colores && colores.length > 0">
                        <div class="row pt-3 px-2 datatable-columnas border-bottom border-2 border-dark d-none d-lg-grid">
                            <div class="row d-none d-lg-grid" v-for="(field, i2) in fields" :key="i2">
                                <span class="alguna" v-if="datas"><b :class="field.clase ?? 'text-muted'">{{datas[0][field.key]}}</b></span>
                                <p class="">{{field.titulo}}</p>
                            </div>
                        </div> 
                        <div class="row border-bottom py-3 datatable-columnas hovered px-2" v-for="(data, i) in colores" :key="i">
                            <div class="d-flex" role="button" v-for="(field, i2) in fields" :key="i2" @click="eliminarColor(data)">
                                <span class="d-sm-none pe-2" :class="field.claseTitulo ?? 'text-muted'"><small>{{field.titulo}} </small></span>
                                <b v-if="field.key != 'codigo'" :class="field.clase ?? 'text-muted'">{{data[field.key]}}</b>
                                <div v-if="field.key == 'codigo'" class="cuadroColor border" :style="'background-color:'+data[field.key]"></div> 
                            </div>
                        </div> 
                    </div>
                </div>
            </template>
        </container-view>
    </div>
</template>
<style scoped src="@/assets/plantilla/dataList.css"></style>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import {onMounted, ref } from 'vue'
import Color from '@/components/admin/color/color'
import Swal from 'sweetalert2';
import Alert from '@/components/utils/alert'

const colores = ref([]);
onMounted(async()=>{
    colores.value = await Color.getColores();
    console.log(colores)
});
const fields = [
    {
        titulo: 'Nombre:',
        key: 'nombre',
        clase: 'text-primary col-6',
        claseTitulo: "text-success"
    },
    {
        titulo: 'Color:',
        key: 'codigo',
    }
]
const eliminarColor = (color)=>{
    Swal.fire({
        title:"Elimanar",
        text:"Desea eliminar el color: "+color.nombre,
        icon:'question',
        showCancelButton:true
    }).then(async(result)=>{
        if(result.isConfirmed){
            await Color.deleteColor(color.id).then((res)=>{
                Alert.Success({text: 'Se eliminó el color con éxito'}).then(()=>{
                   colores.value = res.data.colores;
                })
            }).catch(()=>{
                Alert.Error({text: 'Ocurrió un error al borrar el color'})
            })               
        }
    })
}
// usuario = useAuthStore.usuario
</script>
<style scope>
    .cuadroColor{
  width: 30px;
  height: 30px;

}
.cuadroColorCreate{
  width: 100px;
  height: 100px;
}
</style>
