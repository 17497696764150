<template>
    <div>
        <container-view titulo="Datos de cliente">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="$v.apellido.$invalid && send ? true : false" msj="Debe ingresar un apellido."></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="apellido" v-model="cliente.apellido" placeholder="Apellido" class="form-control" :class="$v.apellido.$invalid && send ? 'is-invalid' : ''" />
                            <label for="email">Apellidos</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="$v.name.$invalid && send ? true : false" msj="Debe ingresar un nombre."></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="email" v-model="cliente.name" placeholder="Nombre" class="form-control" :class="$v.name.$invalid && send ? 'is-invalid' : ''" />
                            <label for="email">Nombres</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="$v.dni.$invalid && send ? true : false" msj="Debe ingresar un DNI válido"></tool-tip>
                        <div class="form-floating">
                            <input type="number" id="dni" v-model="cliente.dni" placeholder="Nombre" class="form-control" :class="$v.dni.$invalid && send ? 'is-invalid' : ''" />
                            <label for="email">DNI</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group">
                        <div class="form-floating">
                            <select name="" id="perfil" class="form-control" :class="$v.perfil_id.$invalid && send ? 'is-invalid' : ''" @change="alertChangeEstado(cliente.perfil_id)">
                                <option :value="perfil.id" v-for="perfil in perfiles" :key="perfil.id">{{perfil.descripcion}}</option>
                            </select>
                            <label for="perfil">Tipo de usuario</label>
                        </div>
                    </div>                
                </div>
            </template>
        </container-view>
        <container-view titulo="Localización">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-4 form-group my-3">
                        <tool-tip :isInvalid="$v.provincia.$invalid && send ? true : false" msj="Debe ingresar una Provincia"></tool-tip>
                        <div class="form-floating">
                            <select  class="form-control" v-model="cliente.provincia" placeholder="Provincia" id="provincia" :class="$v.provincia.$invalid && send ? 'is-invalid' : ''">
                                <option :value="null" :selected="cliente.provincia == null ? true : false"> - Seleccione provincia - </option>
                                <option v-for="(provincia, i ) in provincias" :key="i" :value="provincia.nombre" :selected="cliente.provincia == provincia.nombre ? true : false">{{provincia.nombre}}</option>
                            </select>
                            <label for="provincia">Provincia</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="$v.localidad.$invalid && send ? true : false" msj="Debe ingresar una Localidad"></tool-tip>
                        <div class="form-floating">
                            <select  class="form-control" v-model="cliente.localidad" placeholder="Provincia" id="localidad" :class="$v.localidad.$invalid && send ? 'is-invalid' : ''">
                                <option :value="''" :selected="cliente.localidad == '' ? true : false"> - Seleccione localidad - </option>
                                <option v-for="(localidad, i ) in localidades" :key="i" :value="localidad.nombre" :selected="cliente.localidad == localidad.nombre ? true : false">{{localidad.nombre}}</option>
                            </select>
                            <label for="localidad">Localidad</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-2 form-group my-3">
                        <tool-tip :isInvalid="$v.cod_postal.$invalid && send ? true : false" msj="Por favor, ingrese su Codigo Postal."></tool-tip>
                        <div class="form-floating">
                            <input type="text" v-model="cliente.cod_postal" class="form-control" id="cod_postal" placeholder="Dod. Postal" :class="$v.cod_postal.$invalid && send ? 'is-invalid' : ''"/> 
                            <label for="cod_postal">Cod. Postal</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group my-3">
                        <tool-tip :isInvalid="$v.domicilio.$invalid && send ? true : false" msj="Por favor, ingrese su domicilio."></tool-tip>
                        <div class="form-floating">
                            <input type="text" v-model="cliente.domicilio" class="form-control" id="domicilio" placeholder="Domicilio" :class="$v.domicilio.$invalid && send ? 'is-invalid' : ''"/> 
                            <label for="domicilio">Domicilio</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="$v.telefono.$invalid && send ? true : false" msj="Por favor, ingrese su domicilio."></tool-tip>
                        <div class="form-floating">
                            <input type="number" v-model="cliente.telefono" class="form-control" id="telefono" placeholder="Teléfono" :class="$v.telefono.$invalid && send ? 'is-invalid' : ''"/> 
                            <label for="telefono">Teléfono</label>
                        </div>
                    </div>   
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="$v.email.$invalid && send ? true : false" msj="Error en el email ingresado"></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="email" v-model="cliente.email" placeholder="Email" class="form-control" :class="$v.email.$invalid && send ? 'is-invalid' : ''" />
                            <label for="email">Email</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Confirmar modificación">
            <template v-slot:body>
                 <div class="d-sm-flex justify-content-end">
                    <button class="btn btn-primary col-sm-auto col-12" @click="updateCliente"><i class="fa fa-times me-1"></i> Modificar</button>
                </div>
            </template>
        </container-view>
        <container-view titulo="Sus pedidos pedidos">
            <template v-slot:body>
                <div class="row">
                    <data-list :fields="fields" :datas="pedidos?.data" @evento="verPedido($event)"></data-list>
                    <view-paginate :pages="pedidos" @evento="getPedido($event)"></view-paginate>
                </div>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
import ContainerView from '@/components/admin/template/Container'
import { ref, onMounted, watchEffect, computed } from 'vue'
import Alert from '@/components/utils/alert'
import Clientes from './cliente'
import Perfil from '@/components/admin/perfil/perfil'
import ToolTip from '@/components/admin/template/ToolTip'
import ViewPaginate from '@/components/admin/template/ViewPaginate'
import DataList from '@/components/admin/template/DataList'
import Fecha from '@/components/utils/fecha'
import Pedido from '@/components/admin/pedido/pedido'
import { $v as validation } from '@/components/admin/usuario/validations'
import Axios from 'axios'

const route = useRoute();
const router = useRouter();
const perfiles = ref();
const send = ref(false);
const localidades = ref({})
const provincias = ref();
const cliente = ref({
    name: null,
    email: null,
    perfil:{
        descripcion:null
    }
})
const $v = validation(cliente);
const pedidos = ref();

const fields = [
    {
        titulo: 'Observaciones:',
        key: 'observaciones',
        clase: 'text-muted',
        claseTitulo: "text-success"
    },
    {
        titulo: 'Fecha:',
        key: 'created_at',
    },
    {
        titulo: 'Total:',
        key: 'total',
        append: "$"
    },
    {
        titulo: 'Estado:',
        key: 'status',
    },
    {
        titulo: 'Estado detalle:',
        key: 'status_detail',
    }
]

onMounted(async ()=>{
    await Clientes.getCliente(route.params.id).then((e)=>{
        cliente.value = e 
    }).catch(()=>{
        Alert.Error({text: 'No se encontro el cliente'}).then(()=>{
            router.push({ name: 'ListarCliente' })
        })
    })
    await getPedido('?page=1')
    await Perfil.getPerfiles().then( e => perfiles.value = e )
})
const getPedido = async (page)=>{
    if(page)
        await Pedido.getPedidoByCliente(route.params.id, page).then( e => pedidos.value = e )
}
const updateCliente = ()=>{
    send.value = true;
    if($v.value.$invalid)
        return false
    Clientes.update(cliente.value)
    .then(()=>{
        Alert.Success({text: 'Cliente modificado con éxito'}).then(()=> router.push({ name: 'ListarCliente' }) )
    })
    .catch(()=> Alert.Error({text: 'Ocurrió un error al modificar cliente'}) )
}

const verPedido = (data) => router.push({ name: 'VerPedido', params:{id: data.id} }) 

const getProvincia = ()=>{
    Axios.get("https://api-localidades.mgutierrez.ar/provinces")
    .then((res) => {
        if (res.data) {
            provincias.value = res.data;
            provincias.value.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        }
    }).catch(() => {
        Alert.Error({text: `No se han podido obtener las provincias, intente más tarde.`});
    });
}
getProvincia();
const getLocalidades = (provinciaId)=>{
    return Axios.get("https://api-localidades.mgutierrez.ar/localidades?id="+provinciaId)
    .then((res) => {
        if (res.data) {
            let localidades = res.data;
            localidades.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
            return localidades
        }
    })
}
const finProvinciaId = computed(()=>{
    if(provincias.value){
        let provinciaId = provincias.value.find((e)=> e.nombre == cliente.value.provincia)?.id
        return provinciaId
    }
    return null
})

const alertChangeEstado = (anterior)=>{
    console.log(anterior)
    let select = event.target
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de cambiar el estado de este cliente.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(!response.isConfirmed){
            cliente.value.perfil_id = anterior
            select.value = anterior
        }else{
            cliente.value.perfil_id = select.value
        }
    })
}


watchEffect(async ()=>{
    if(finProvinciaId.value){
        localidades.value = await getLocalidades(finProvinciaId.value)
    }
})
watchEffect(()=>{
    if(pedidos.value)
        pedidos.value.data.filter((e)=> e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY hh:ss"))
})
</script>