<template>
    <div class="container-fluid">
        <div class="row bg-secondary py-2 px-xl-5 py-4">
            <slot name="usuario"></slot>
        </div>
        <div class="row align-items-center py-3 px-xl-5">
            <div class="col-lg-3 d-none d-lg-block">
                <a href="" class="text-decoration-none" translate="no">
                    <h1 class="m-0 display-5 font-weight-semi-bold" translate="no"><span class="text-primary font-weight-bold border px-3 mr-1" translate="no"><i class="fa-brands fa-shopify"></i></span><span translate="no">OnlineSur</span></h1>
                </a>
            </div>
            <div class="col-lg-6 col-7 text-left pr-0">
                
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar Productos" v-model="search" v-on:keyup.enter="buscarProducto"/>
                        <div class="input-group-append">
                            <span class="input-group-text bg-transparent text-primary">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                
            </div>
            <div class="col-lg-3 col-5 text-right" v-if="UseAuthStore().$state.isLoggin">
                <button @click="isAudio" class="btn border px-sm-4 px-0 col-6 col-sm-auto" title="Ver mi perfil">
                    <!-- <i class="fas fa-sound text-primary"></i> -->
                    <i class="fa-solid fa-volume-high text-primary" v-if="audio=='on'"></i>
                    <i class="fa-solid fa-volume-xmark text-primary" v-if="audio=='off'"></i>
                </button>
                <router-link :to="{ name: 'VerCarrito' }" class="btn border col-6 col-sm-auto px-0 px-sm-3" :title="'Tienes '+useCarritoStore.storage.length+ ' productos en el carrito' ">
                    <i class="fas fa-shopping-cart text-primary"></i>
                    <span class="badge text-muted"><b>{{useCarritoStore.storage.length}}</b></span>
                </router-link>
            </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import { UseAuthStore } from '@/store/authStore'
import { UseCarritoStore } from '@/store/carritoStore'
import { ref, defineProps, onMounted } from 'vue'
import { useRouter } from 'vue-router';
const props = defineProps({
  search: {
      type: String,
      default: null
  },
})
onMounted(()=>{console.log('onMounter menu ',props)})
const router = useRouter();
const useCarritoStore = UseCarritoStore();
if(!localStorage.getItem('audio'))
    localStorage.setItem('audio', 'on')
const audio = ref(localStorage.getItem('audio'));
const search = ref(props.search);
const isAudio = ()=>{
    if(!audio.value){
        audio.value = 'on'
        localStorage.setItem('audio', audio.value);
    }
    else if(audio.value == "off"){
        audio.value = 'on'
        localStorage.setItem('audio', audio.value);
    }
    else{
        audio.value = 'off'
        localStorage.setItem('audio', audio.value);
    }
}
const buscarProducto = () => {
    console.log('search', search.value)
    router.push({path:'/productos', query: { search: search.value }})
}
</script>