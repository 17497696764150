import { createWebHistory, createRouter } from "vue-router";
import { UseAuthStore } from "@/store/authStore";
import { UsePerfilStore } from "@/store/perfilStore";
import Inicio from '@/components/Inicio'

import ListProductos from '@/components/publico/producto/ListProductos'
import ProductDetail from '@/components/publico/producto/ProductDetail'
import Login from '@/components/Login'
import Registrar from '@/components/Registrar'
import VerificarEmail from '@/components/VerificarEmail'
import RecuperarContraseña from '@/components/RecuperarContraseña'
import CambioContraseña from '@/components/cambioContraseña'

import VerCliente from '@/components/publico/cliente/VerCliente'
import VerCarrito from '@/components/publico/carrito/VerCarrito'

import ListarPedidosCliente from '@/components/publico/pedido/ListarPedidos'
import VerPedidoCliente from '@/components/publico/pedido/VerPedido'

import { AdminRoutes } from './admin'


const carrito = [
    {
        path: "/carrito",
        name:"VerCarrito",
        component: VerCarrito,
        meta: {
            private:true,
            cliente: true
        },
    }
]
const listarProductos = [
   
    {
        path:"/productos/:categoria?/:subCategoria?/:search?",
        name:"ListProductos",
        component: ListProductos
    },
]
/* const listarProducosSubCategoria = [
    {
        path:"/productos/sub-categoria/:categoria",
        name:"listarProducosSubCategoria",
        component: ListProductos
    }
] */
const producto = [
    {
        path: "/detalle-producto/:id",
        name:"ProductDetail",
        component: ProductDetail,
        meta: {
            private:false,
        },
    }
]
const login = [
    {
        path: "/login",
        name:"Login",
        component: Login,
    }
]
const registrar = [
    {
        path:"/registrar",
        name:"Registrar",
        component: Registrar
    }
    
]
const recuperarContraseña = [
    {
        path: "/recuperar-password",
        name: "RecuperarContraseña",
        component: RecuperarContraseña
    }
]
const cambiarContraseña=[
    {
        path: "/cambiar-password",
        name: "cambiarContraseña",
        component: CambioContraseña,
        props: true
    }
]
const verificar = [
    {
        path:"/verificar-correo",
        name:"VerificarCorreo",
        component: VerificarEmail
    }
]
const cliente = [
    {
        path:"/mis-datos",
        name:"VerCliente",
        component: VerCliente,
        meta: {
            private:true,
            cliente: true
        },
    }
]
const pedido = [
    {
        path:"/mis-pedidos",
        name:"ListarPedidosCliente",
        component: ListarPedidosCliente,
        meta: {
            private:true,
            cliente: true
        },
    },
    {
        path:"/detalle-pedido/:id",
        name:"VerPedidoCliente",
        component: VerPedidoCliente,
        meta: {
            private:true,
            cliente: true
        },
    }
]

const inicio = [
    {
        path: "/",
        name:"Inicio",
        component: Inicio,
    },
    
];

const routes = [
    ...inicio,
    ...login,
    ...listarProductos,
    ...producto,
    ...registrar,
    ...verificar,
    ...carrito,
    ...AdminRoutes,
    ...verificar,
    ...cliente,
    ...recuperarContraseña,
    ...cambiarContraseña,
    ...pedido
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    const useAuthStore = UseAuthStore();
    const usePerfilStore = UsePerfilStore();
    if(to.matched.length === 0){
        next({name: 'Inicio'})
    }
    else if(to.meta.private){
        await useAuthStore.getUserProfile()
        if(useAuthStore.$state.isLoggin){
            if(!usePerfilStore.descripcion)
                await usePerfilStore.getPerfil()
            if(to.meta.admin){
                if(usePerfilStore.descripcion == "Administrador")
                    return next();
                else{
                    return next({name:'Login'}) // deberia ser algun error en la ruta
                }
            }
            else if(to.meta.cliente){
                if(usePerfilStore.descripcion == "Cliente")
                // if(usePerfilStore.state.descripcion == "Cliente")
                    return next();
                else
                    return next({name:'Login'}) // deberia ser algun error en la ruta
            }
            else next(); // si no es admin y no es cliente no deberia estar aca
        }
        else
            next({name:'Login'})
    }
    else{
        next();
    }
})
  
export default router