<template>
    <div>
        <container-view titulo="Listado de marcas" classCard="bg-white" :isCard="true">
            <template v-slot:top>
                <div class="d-flex col-12 justify-content-end mb-4">
                    <router-link :to="{name: 'CreateMarca'}" class="btn btn-primary col-12 col-sm-auto"><i class="fa fa-plus me-2"></i>Agregar Marca</router-link>
                </div>
            </template>
            <template v-slot:body>
                <data-list :fields="fields" :datas="marcasConfechaFormateada" @evento="opciones($event)"></data-list>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import Marca from './marca';
import { ref, computed } from 'vue';
import ContainerView from '@/components/admin/template/Container'
import DataList from '@/components/admin/template/DataList'
import { useRouter } from 'vue-router'
import Fecha from '@/components/utils/fecha'
const router = useRouter();
const marcas = ref();
const fields = [
    {
        titulo: 'Nombre:',
        key: 'marca',
        clase: 'text-primary',
        claseTitulo: "text-success"
    },
    {
        titulo: 'Creado:',
        key: 'created_at'
    }
]
const getMarcas = async()=>{
    await Marca.getMarcas().then((e)=>{
        marcas.value = e.data.marcas
    })
}
getMarcas();
const marcasConfechaFormateada = computed(()=>{
    if(!marcas.value) return false
    
    return marcas.value.filter((e)=> {
        console.log(e.created_at)
        if(e.created_at)
            return e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY")
        return e
    })
})
console.log(marcasConfechaFormateada)
const opciones = (marca)=>{
   router.push({ name: 'VerMarca', params:{ id: marca.id }})
}
</script>