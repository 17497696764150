import Axios from 'axios'

export default class Slider {
    static createSlider(slider){
        return new Promise((resolve, reject)=>{
            Axios.post('slider', slider).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getSliders(){
        return new Promise((resolve, reject)=>{
            Axios.get('slider').then((e)=>{
                return resolve(e.data)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static deleteSlider(slider){
        //delete slider
        return new Promise((resolve, reject)=>{
            Axios.delete('slider/'+slider.id).then((e)=>{
                return resolve(e.data)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}