// import { defineStore } from 'pinia'
// import Axios from 'axios'
// import { UseAuthStore } from '@/store/authStore'
// import { reactive, readonly } from 'vue'
// export const UsePerfilStore = defineStore('perfil', ()=>{
//     // state: ()=>({
//     //     perfil_id: null,
//     //     descripcion: null
//     // }),
//     const state = reactive({
//         perfil_id: null,
//         descripcion: null
//     })
//     //getters
//     const actions = {
//         getPerfil(){
//             return new Promise((resolve, reject)=>{
//                 const useAuthStore = UseAuthStore();
//                 if(!useAuthStore.usuario)
//                     return null
//                 state.perfil_id = useAuthStore.usuario.perfil_id
//                 Axios.get("/perfil/"+state.perfil_id, {id:state.perfil_id}).then((e)=>{
//                     console.log("va a buscar el perfil")
//                     state.perfil_id = e.data.perfil.id;
//                     state.descripcion = e.data.perfil.descripcion;
//                     return resolve(e)
//                 }).catch((e)=>{
//                     return reject(e)
//                 })
//             })
//         },
//         clear(){
//             this.$reset();
//         }
//     }
//     return { state: readonly(state), ...actions }
// })
import { defineStore } from 'pinia'
import Axios from 'axios'
import { UseAuthStore } from '@/store/authStore'
export const UsePerfilStore = defineStore('perfil', {
    state: ()=>({
        perfil_id: null,
        descripcion: null
    }),
    //getters
    actions: {
        async getPerfil(){
            return new Promise((resolve, reject)=>{
                const useAuthStore = UseAuthStore();
                if(!useAuthStore.usuario)
                    return
                this.perfil_id = useAuthStore.usuario.perfil_id
                Axios.get("/perfil/"+this.perfil_id, {id:this.perfil_id}).then((e)=>{
                    this.perfil_id = e.data.perfil.id;
                    this.descripcion = e.data.perfil.descripcion;
                    return resolve(e)
                }).catch((e)=>{
                    return reject(e)
                })
            })
        },
    },
})