import Axios from 'axios';
export default class Color {
    static getCategorias(){
        return new Promise((resolve, reject)=>{
            Axios.get('categoria').then((e)=>{
                return resolve(e.data.categorias)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static editCategoria(categoria){
        return new Promise((resolve, reject)=>{
            Axios.put('categoria/'+categoria.id, {params:{categoria}}).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static editSubCategoria(subcategoria){
        return new Promise((resolve, reject)=>{
            Axios.put('subcategoria/'+subcategoria.id, {params:{subcategoria}}).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static deleteCategoria(categoria){
        return new Promise((resolve, reject)=>{
            Axios.delete('categoria/'+categoria.id,{params:{...categoria}} ).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static deleteSubCategoria(categoria){
        return new Promise((resolve, reject)=>{
            Axios.delete('subcategoria/'+categoria.id, {params:{...categoria}} ).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static createCategoria(categoria){
        return new Promise((resolve, reject)=>{
            Axios.post('categoria',{params:{descripcion: categoria}} ).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
    static createSubCategoria(descripcion, categoria){
        return new Promise((resolve, reject)=>{
            Axios.post('subcategoria',{params:{descripcion: descripcion, categoria:categoria}} ).then((e)=>{
                return resolve(e)
            }).catch(()=>{
                return reject(null)
            })
        })
    }
}