<template>
<div class="d-flex flex-column vh-100 flex-shrink-0 p-3 text-white bg-dark"> 
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"> 
        <svg class="bi me-2" width="40" height="32"> </svg> 
        <span class="fs-4">Mi Menú</span> 
    </a>
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
        <li > 
            <router-link class="nav-link btnMenu" active-class="active" exact aria-current="page" :to="{name: 'Bienvenida'}">
                <i class="fa fa-home"></i><span class="ms-2">Home</span> 
            </router-link>
        </li>
        <li> 
            <router-link class="nav-link text-white btnMenu" active-class="active" aria-current="page" :to="{name: 'ListarCliente'}">
                <i class="fa fa-circle-user"></i><span class="ms-2">Clientes</span> 
            </router-link>
        </li>
        <li> 
            <router-link class="nav-link text-white btnMenu" active-class="active" aria-current="page" :to="{name: 'ListarUsuarios'}">
                <i class="fa fa-users"></i><span class="ms-2">Usuarios</span> 
            </router-link>
        </li>
        <!-- <li> 
            <router-link :to="{name:'ListarColores'}" active-class="active" class="nav-link text-white btnMenu">
             <i class="fa fa-palette"></i><span class="ms-2">Colores</span> 
            </router-link>
        </li> -->
        <li> 
            <router-link :to="{name:'ListarCategorias'}" active-class="active" class="nav-link text-white btnMenu">
             <i class="fa fa-list"></i><span class="ms-2">Categorías</span> 
            </router-link>
        </li>
        <li> 
            <router-link :to="{name:'ListarMarcas'}" active-class="active" class="nav-link text-white btnMenu">
             <i class="fa fa-tags"></i><span class="ms-2">Marcas</span> 
            </router-link>
        </li>
        <li> 
            <router-link :to="{name:'ListarProductos'}" active-class="active" class="nav-link text-white btnMenu">
             <i class="fa fa-cart-arrow-down"></i><span class="ms-2">Productos</span> 
            </router-link>
        </li>
        <li> 
            <router-link :to="{name:'ListarPedidos'}" active-class="active" class="nav-link text-white btnMenu">
             <i class="fa fa-dollar"></i><span class="ms-2">Pedidos</span> 
            </router-link>
        </li>
        <li> 
            <router-link :to="{name:'CreateSlider'}" active-class="active" class="nav-link text-white btnMenu">
             <i class="fa fa-images"></i><span class="ms-2">Imagenes portada</span> 
            </router-link>
        </li>
    </ul>
    <hr>
    <div class="dropdown"> 
        <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"> 
            <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2"> 
            <strong> {{UseAuthStore().usuario?.name}} </strong> 
        </a>
        <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
            <li>
                <router-link class="dropdown-item btnMenu" :to="{name: 'VerUsuario'}">Mi Perfil</router-link>
            </li>
            <li>
                <router-link class="dropdown-item btnMenu" :to="{name: 'ConfiguracionPagina'}">Configuracion</router-link>
            </li>
            <li>
                <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" @click="logout">Salir</a></li>
        </ul>
    </div>
</div>
</template>
<script setup>
import { UseAuthStore } from '@/store/authStore'
import { UsePerfilStore } from '@/store/perfilStore'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
import { ref } from 'vue'
const useAuthStore = UseAuthStore();
const usePerfilStore = UsePerfilStore();
const router = useRouter();
const logout = ()=>{
    useAuthStore.logout().then(()=>{
        console.log(usePerfilStore)
        usePerfilStore.$reset();
        router.push({name:'Inicio'})
    })
}
onMounted(()=>{
    const width = ref()
    const btnMenu = document.querySelectorAll(".btnMenu")
    btnMenu.forEach((element)=>{
        element.addEventListener('click', ()=>{
            width.value = screen.width
            if(width.value < 600 ){
                let menu = document.querySelector(".add")
                menu.querySelector(".menu-area").classList.toggle("esconder-menu")
                menu.querySelector(".contenido-area").classList.toggle("esconder-menu")
                menu.classList.toggle("adminTemplate2")
                menu.classList.toggle("adminTemplate")
            }
        })
    }) 
});
</script>
<style scoped>
body {
    background-color: #eee
}

.nav-link:hover {
    background-color: #525252 !important
}

.nav-link .fa {
    transition: all 1s
}

.nav-link:hover .fa {
    transform: rotate(360deg)
}
.menuStyle{
    width: 100%;
}
@media (min-width: 600px) {
    .menuStyle{
        width: 250px;
    }
}
</style>
