<template>
    <div ref="grecaptcha"></div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from 'vue';
const props = defineProps({
    siteKey: {
        type: String,
        default: ''
    }
})
const grecaptcha = ref();
const emit = defineEmits(['correct'])
// window.location.origin.includes('http://localhost')
function loaded(){
    window.grecaptcha.render(grecaptcha.value, {
    sitekey: props.siteKey,
    'expired-callback': () =>{
        emit('correct', null)
    },
    callback: (response) => {
        emit('correct', response)
        },
    });
}
window.ReCaptchaLoaded = loaded;
    
const scriptcaptcha = document.createElement('script')
scriptcaptcha.type = 'text/javascript';
scriptcaptcha.async = true;
scriptcaptcha.defer = true;
scriptcaptcha.src = 'https://www.google.com/recaptcha/api.js?onload=ReCaptchaLoaded&render=explicit'
document.head.appendChild(scriptcaptcha)
</script>