<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur | Mis pedidos</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá, productos" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar>
            <template v-slot:usuario>
                <span v-if="useAuthStore.$state.usuario">Bienvenido {{useAuthStore.$state.usuario?.name}}</span>
            </template>
        </temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>                    
                        <temp-menu></temp-menu>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Mis Pedidos</h3>
            </div>
        </div>
        <div class="row col-12 col-sm-8 mx-auto d-flex align-items-end">
            <div class="col-12 col-sm-12 col-lg-12">
                <table class="table table-bordered text-center mb-0 tabla-responsiva-pagos">
                    <thead class="bg-secondary text-dark">
                        <tr>
                            <th>Fecha</th>
                            <th>Total</th>
                            <th>Pago</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="align-middle">
                        <tr v-for="(pedido, i) in pedidos.data" :key="i" class="pb-3">
                            <td class="fecha">
                                <span class="mr-2"><small>Fecha: </small></span>
                                <div>
                                    <b>{{Fecha.formatDate(pedido.created_at)}}</b>
                                </div>
                            </td>
                            <td class="total">
                                <span class="mr-2"><small>Total: </small></span>
                                <div>
                                    <b>$ {{pedido.total}}</b>
                                </div>
                            </td>
                            <td class="pago">
                                <span class="mr-2"><small>Pago: </small></span>
                                <div v-if="pedido.status == 'rejected'">
                                    <b class="text-danger">{{pedido.status}}</b>
                                </div>
                                <div v-else-if="pedido.status == 'approved'">
                                    <b class="text-success">{{pedido.status}}</b>
                                </div>
                                <div v-else-if="pedido.status == 'pending'">
                                    <b class="text-warning">{{pedido.status}}</b>
                                </div>
                                <div v-else>
                                    <b>{{pedido.status}}</b>
                                </div>
                            </td>
                            <td class="estado">
                                <span class="mr-2"><small>Estado: </small></span>
                                <div v-if="pedido.estado == 'no enviado'">
                                    <b class="text-danger">{{pedido.estado}}</b>
                                </div>
                                <div v-else>
                                    <b class="text-success">{{pedido.estado}}</b>
                                </div>
                            </td>
                            <td class="boton">
                                <div>
                                    <router-link :to="{ name:'VerPedidoCliente', params: {id: pedido.id} }" class="btn btn-primary btn-sm" title="Ver Detalles"><i class="fa fa-eye"></i></router-link>
                                    <!-- <button class="btn btn-primary btn-sm" title="Ver Detalles"><i class="fa fa-eye"></i></button> -->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p v-if="pedidos.data && pedidos.data.length == 0" class="text-center text-muted mt-4">No a agregado productos a su carrito</p>
                <view-paginate :pages="pedidos" @evento="getPedidos($event)" v-if="pedidos.data && pedidos.data.length > 0"></view-paginate>
            </div>
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<style>

.tabla-responsiva-pagos span{
    display: none;
}
@media (max-width: 599px) {
    .tabla-responsiva-pagos tbody{
        border-top: 0!important;
    }
    .tabla-responsiva-pagos span{
        display: block;
    }
    .tabla-responsiva-pagos{
        overflow: scroll;
    }
    .tabla-responsiva-pagos td{
        border: none!important;
    }
    .tabla-responsiva-pagos thead{
        display: none;
    }
    .tabla-responsiva-pagos tbody tr{
        display: grid;
        grid-template-areas: 'fecha fecha fecha boton'
                            'total total total total'
                            'pago pago estado estado';
        grid-template-columns: 1fr 1fr 1fr 1fr;
        border: 1px solid #dfdfdf!important;
    }
    .tabla-responsiva-pagos .fecha{
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        grid-area: fecha;
        padding: 0 5px!important;
    }
    .tabla-responsiva-pagos .total{
        grid-area: total;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        padding: 0 5px!important;
    }
    .tabla-responsiva-pagos .pago{
        grid-area: pago;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        padding: 0 5px!important;
    }
    .tabla-responsiva-pagos .estado{
        grid-area: estado;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        padding: 0 5px!important;
    }
    .tabla-responsiva-pagos .boton{
        grid-area: boton;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
    }

}
</style>
<script setup>
import TempTopBar from '@/components/template/TempTopBar'
import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import { UseAuthStore } from '@/store/authStore'
import { ref } from 'vue';
import Pedido from '@/components/admin/pedido/pedido'
import Fecha from '@/components/utils/fecha'
import ViewPaginate from '@/components/template/ViewPaginate'
// import { useRouter } from 'vue-router'
const useAuthStore = UseAuthStore()
const user = ref({})
// const router = useRouter();
user.value = {...useAuthStore.usuario}
const pedidos = ref({});
const getPedidos = (pagina) =>{
    Pedido.getPedidoByCliente(user.value.id, pagina).then( e => pedidos.value = e )
}
getPedidos();

</script>