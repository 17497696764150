<template>
   <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar></temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <temp-menu></temp-menu>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Recuperar Contraseña</h3>
            </div>
        </div>
        <div  class="container pt-1 col-sm-10 col-md-8 col-lg-6">
            
                <p>Por favor revise su correo hemos enviado un código que debe ingresar para cambiar su contraseña.</p>
                <form  v-on:submit.prevent="enviar">
                    <div class="control-group ">
                        <label>Ingrese el código de verificación que enviamos a su correo.</label>
                        <input type="codigo" v-model="user.codigo_reset" class="form-control" id="email" placeholder="Codigo de verificación"
                            required="required" data-validation-required-message="Por favor ingrese el código enviado a su email" />
                        <p class="help-block text-danger" v-if="$v.codigo_reset.$invalid && enviado ? true : false">Por favor ingrese el código que le enviamos</p>
                        
                    </div>
                    <div class="control-group ">
                        <label for="password">Nueva contraseña: </label>
                        <input type="password" v-model="user.password" class="form-control" id="password" placeholder="Nueva password"
                            required="required" data-validation-required-message="Por favor ingrese una contraseña" />
                        <p class="help-block text-danger" v-if="$v.password.$invalid && enviado ? true : false">Por favor ingrese una contraseña</p>
                    </div>
                    <div class="control-group ">
                        <label for="repetirPassword">Repetir contraseña: </label>
                        <input type="password" v-model="user.repetirPassword" class="form-control" id="repetirPassword" placeholder="Repetir password"
                            required="required" data-validation-required-message="Por favor repita su contraseña" />
                        <p class="help-block text-danger" v-if="$v.repetirPassword.$invalid && enviado ? true : false">Contraseña no coinciden</p>
                        
                    </div>
                        <button id="btnEnviar" class="btn btn-primary">Enviar</button>
                </form>
            

        </div>



        <temp-footer></temp-footer>
        
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
 import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import TempTopBar from '@/components/template/TempTopBar'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from "@vuelidate/validators";
import { ref } from '@vue/reactivity'
//import axios from 'axios'
//import Swal from 'sweetalert2'
import { defineProps  } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter();
const route = useRoute();

const props = defineProps({
    email: null,
})
const enviado = ref(false)
const user = ref({
                codigo_reset:'',
                password:'',
                repetirPassword:'',
                email:props.email
                

                })
                const confirmPassword = (password) => {
    console.log(password)
    return password === user.value.password;
};
const rules = {
      
            codigo_reset:{required, minLength: minLength(6)},
            password:{required},
            repetirPassword: {required, confirmPassword: confirmPassword },
            
    
    }
const $v = useVuelidate(rules, user);
const enviar = ()=>{
    enviado.value = true;
    console.log(user.value)
    $v.value.$touch()
    if($v.value.$invalid){
        return false
    }
    let params = {...user.value, email:route.query.email, token:route.query.token}
    axios.post('/cambiar-contraseña', params).then(res=>{
        
        res.status == 200 ? Swal.fire('Exito', 'Cambio de contraseña exitoso', 'success')
        : Swal.fire('Error', 'Algo salió mal al intentar cambiar la contraseña', 'error')
        
        router.push({name:'Login'})
        
    }).catch((error)=>{
        Swal.fire('Error', error.response.data.message, 'error')
    })
    
}
</script>