import { defineStore } from 'pinia'
import Axios from 'axios'
import {UsePerfilStore} from '@/store/perfilStore'
import { UseCarritoStore } from '@/store/carritoStore'
export const UseAuthStore = defineStore('auth', {
    state: ()=>({
        token: null,
        isLoggin: null,
        usuario: null
    }),
    //getters
    actions: {
        async login(data){
            return new Promise((resolve, reject)=>{
                Axios.post("/login", data).then((e)=>{
                    this.token = e.data.token;
                    return resolve(e)
                }).catch((e)=>{
                    return reject(e)
                })
            })
        },
        async getUserProfile(){
            const token = sessionStorage.getItem('token');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            return new Promise((resolve, reject)=>{

                Axios.get('/user-profile').then((e)=>{
                    if(!e.data.perfil_id){
                        throw {response: {data: {message:'Usuario no confirmado'}}}
                    }
                    return e
                }).then((e)=>{                    
                    this.usuario = e.data
                    this.isLoggin = true
                    return resolve(e)
                })
                .catch((error)=>{
                    this.$reset()
                    return reject(error)
                })
            })
        },
        async logout(){
            try{
                let response = await Axios.get("/logout")
                this.$reset()
                const usePerfilStore = UsePerfilStore()
                const useCarritoStore = UseCarritoStore()
                if(usePerfilStore)
                    usePerfilStore.$reset();
                if(useCarritoStore)
                    useCarritoStore.borrarCarrito();
                return response
            }catch(error){
                return error
            }
        }
    },
})