import Marca from '@/components/admin/marca/marca'
import Alert from '@/components/utils/alert'
import Swal from 'sweetalert2'

export default class MarcaUtils {
    static async ModalCreate(){
        const html= `
        <div class="form-floating">
            <input type="text" id="nuevaMarca" placeholder="Nueva Marca" class="form-control" />
            <label for="email">Nueva Marca</label>
            <p class="d-none text-danger" id="mensajeMarca">Debe ingresar una marca</p>
        </div>
        `;
        return Swal.fire({
            title:'Ingrese la marca',
            html: html,
            showCancelButton: true, 
            cancelButtonText: "Cancelar", 
            cancelButtonColor: "#343a40",
            confirmButtonText: "Guardar",
            confirmButtonColor: "#198754",
            preConfirm: ()=>{
                if(document.querySelector("#nuevaMarca").value == ""){
                    document.querySelector("#mensajeMarca").classList.remove('d-none')
                    document.querySelector("#nuevaMarca").classList.add('is-invalid')
                    return false
                }
            } 
        })
        .then(async (resolve)=>{
            if(resolve.isConfirmed){
                await Marca.Create({marca:document.querySelector("#nuevaMarca").value})
                .then(()=>{
                    return  Alert.Success({text: 'Marca guardada con éxito'})
                }).catch(()=>{
                    return  Alert.Error({text: 'Error al guardar marca'})
                })
            }
        })
        
    }
}