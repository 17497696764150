<template>
<div>
    <div class="tabla" v-if="datas && datas.length > 0">
        <div class="row pt-3 px-2 datatable-columnas">
            <div class="row border-bottom border-2 border-dark mb-3 d-none d-lg-grid" v-for="(field, i2) in fields" :key="i2">
                <span class="alguna" v-if="datas"><b :class="field.clase ?? 'text-muted'">{{datas[0][field.key]}}</b></span>
                <p class="">{{field.titulo}}</p>
            </div>
        </div> 
        <div class="row border-bottom py-3 datatable-columnas hovered px-2" v-for="(data, i) in datas" :key="i">
            <div class="d-flex" role="button" v-for="(field, i2) in fields" :key="i2" @click="$emit('evento', data)">
                <span class="d-sm-none pe-2" :class="field.claseTitulo ?? 'text-muted'"><small>{{field.titulo}} </small></span>
                <b :class="field.clase ?? 'text-muted'" class="datatable-word-break"><span v-if="field.append">{{field.append}}</span>{{getKeys(data, field.key)}}</b>
            </div>
        </div> 
    </div>
    <div v-else>
        <p>No se encontraron datos...</p>
    </div>
</div>
</template>
<style scoped src="@/assets/plantilla/dataList.css"></style>
<script setup>
import { defineProps  } from 'vue'
defineProps({
    fields: null,
    datas: []
})
const getKeys = (data, key)=>{
    //busca la key de un array en el caso que sea un objeto
    let keys = key.split('.')
    let retorno = data;
    keys.forEach((e) => {
        for(var propiedad in retorno){
            if(e == propiedad){
                retorno = retorno[propiedad];
                return true;
            }
        }
    })
    return retorno
}
</script>