<template>
    <div>
        <container-view titulo="Ultimos Pedidos realizados">
            <template v-slot:body>
                <data-list :fields="fields" :datas="pedidos?.data" @evento="verPedido($event)"></data-list>
                <view-paginate :pages="pedidos" @evento="getPedidos($event)"></view-paginate>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import Pedido from '@/components/admin/pedido/pedido'
import ViewPaginate from '@/components/admin/template/ViewPaginate'
import DataList from '@/components/admin/template/DataList'
import ContainerView from '@/components/admin/template/Container'
import { ref, watchEffect } from 'vue'
import Fecha from '@/components/utils/fecha'
import Alert from '@/components/utils/alert'
import { useRouter } from 'vue-router'

const router = useRouter();
const pedidos = ref();
const getPedidos = async (pagina)=>{
    await Pedido.getAllPedidos(pagina).then( e => pedidos.value = e )
}
getPedidos();
const fields = [
    {
        titulo: 'Observaciones:',
        key: 'observaciones',
        clase: 'text-muted',
        claseTitulo: "text-success"
    },
    {
        titulo: 'Fecha:',
        key: 'created_at',
    },
    {
        titulo: 'Total:',
        key: 'total',
        append: "$"
    },
    {
        titulo: 'Estado:',
        key: 'status',
    },
    {
        titulo: 'Estado detalle:',
        key: 'status_detail',
    },
    {
        titulo: 'Estado envio:',
        key: 'estado'
    }
]
watchEffect(()=>{
    if(pedidos.value){
        pedidos.value.data.filter((e)=> e.status == 'approved' ? fields[3].clase='text-success' : fields[3].clase='text-danger')
        pedidos.value.data.filter((e)=> e.status_detail == 'accredited' ? fields[4].clase='text-success' : fields[4].clase='text-danger')
        pedidos.value.data.filter((e)=> e.estado == 'no enviado' ? fields[5].clase='text-danger' : fields[5].clase='text-success')
        pedidos.value.data.filter((e)=> e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY hh:ss"))
    }
})
const verPedido = (data)=> {
    Alert.Option({
        titles: 'Opciones:',
        icon:'' ,
        text: '', 
        confirmButtonText: 'Pedido enviado',
        confirmButtonColor: '#198754',
        cancelButtonText: 'Ver detalles', 
        cancelButtonColor:"#0d6efd"
    }).then(async (response)=>{
        if(response.dismiss == 'cancel'){
            router.push({ name: 'VerPedido', params:{id: data.id} }) 
        }
        if(response.isConfirmed){
            await Pedido.setPedidoEnviado(data.id)
            .then((e) => {
                Alert.Success({text: e.message}).then(()=>{
                    getPedidos()
                })
            })
            .catch( () => Alert.Error({text: 'Ocurrió un error al seleccionar el pedido como enviado.'}) )
        }
    })
}
</script>