<template>
    <nav aria-label="..." class="pt-4">
        <ul class="pagination justify-content-center">
            <template v-for="(pagina, i) in pages.links" :key="i">
                <template v-if="i >= pages.current_page - 1 && i <= pages.current_page + 1 || i == 0 || i > pages.total">
                    <li class="page-item active text-dark" v-if="pagina.active" role="button" @click="getPage(pagina.url)">
                    <a class="page-link text-dark">{{getNameLabel(pagina.label)}} <span class="sr-only">(current)</span></a>
                    </li>
                    <li role="button" class="page-item"><a class="page-link text-dark" v-if="!pagina.active" @click="getPage(pagina.url)">{{getNameLabel(pagina.label)}}</a></li>
                </template>
            </template>
        </ul>
    </nav>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import { defineProps, defineEmits } from 'vue'
defineProps({
    pages: {
        type: Object,
        default(){
            return []
        }
    },
})
const emit = defineEmits(['evento'])
const getPage = (pagina)=>{
    if(!pagina) return false
    if(pagina)
        pagina = pagina.split("=")[1]    
    emit('evento', pagina)
}
const getNameLabel = (data)=>{
    if(data.split(".").length > 1)
        return data.split(".")[1]
    return data
}
</script>