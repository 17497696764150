import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export const $v = data => useVuelidate(rules, data)

export const rules = {
    name: { required },
    apellido: { required },
    dni: { required },
    perfil_id: { required },
    email: { required, email },
    localidad: { required },
    provincia: { required },
    domicilio: { required },
    cod_postal: { required },
    telefono: { required },
}