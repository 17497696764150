import Axios from 'axios';

export default class User {
    static register(user){
        return new Promise((resolve, reject)=>{
            Axios.post('register', user).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }

    static updateData(user){
        return new Promise((resolve, reject)=>{
            Axios.put('/user/'+user.id, user).then((e)=>{
                return resolve(e.data)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getAllUsers(){
        return new Promise((resolve, reject)=>{
            Axios.get('user').then((e)=>{
                console.log(e)
                return resolve(e.data)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getUser(id){
        return new Promise((resolve, reject)=>{
            Axios.get('user/'+id).then((e)=>{
                return resolve(e.data)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static async createUser(user){
        return new Promise((resolve, reject)=>{
            Axios.post('/user', user )
            .then( e => resolve( e ))
            .catch(error => reject(error) )
        })
    }
    
}