<template>
    <div>
        <container-view titulo="Vonfiguración" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row">
                    <div class="col-sm-2 col-12 form-group">
                        <div class="col-12 d-flex">
                            <label class="mb-3 me-3 my-auto" for="">Color uno: </label>
                            <input type="color" id="color" v-model="color.primary" placeholder="Seleccione el color" />
                        </div>
                    </div>
                    <div class="col-sm-2 col-12 form-group">
                        <div class="col-12 d-flex">
                            <label class="mb-3 me-3 my-auto" for="">Color Dos: </label>
                            <input type="color" id="color" v-model="color.secondary" placeholder="Seleccione el color" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-danger me-3" @click="reset"><i class="fa fa-times me-2"></i>Reset colores</button>
                    <button class="btn btn-primary" @click="guardar"><i class="fa fa-plus me-2"></i>Guardar</button>
                </div>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import { ref } from 'vue'
import Axios from 'axios'
import Alert from '@/components/utils/alert'

const color = ref({
    primary:null,
    secondary:null
});

const getColoresPagina = ()=>{
     Axios.get('/configcolorpagina').then((e)=>{
        color.value.primary =  e.data.color.primary;
        color.value.secondary =  e.data.color.secondary;
    })
}
getColoresPagina();

const guardar = ()=>{
    Axios.post('configcolor', color.value).then(()=>{
        Alert.Success({text: 'Colroes modificados con éxito'}) 
    }).catch(()=>{
        Alert.Error({text: 'Error al guardar color'})
    })
}
const reset = ()=>{
    color.value = {
        primary:"#D19C97",
        secondary:"#d1dbff"
    };
}
</script>