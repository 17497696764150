<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá" />
            <meta property="og:type" content="website">
        </Teleport>
        <!-- Topbar Start -->
        <temp-top-bar>
            <template v-slot:usuario>
                <span v-if="useAuthStore.$state.usuario">Bienvenido {{useAuthStore.$state.usuario?.name}}</span>
            </template>
        </temp-top-bar>
        <!-- Topbar End -->

        <!-- Navbar Start -->
        <div class="container-fluid mb-5">
            <div class="row border-top px-xl-5">
                <div class="col-lg-3 d-none d-sm-block">
                    <temp-categories :isInitialOpen="true" />
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <temp-menu></temp-menu>
                    </nav>
                    <temp-carousel :images="imagenesSlider"></temp-carousel>           
                </div>
            </div>
        </div>
        <!-- Navbar End -->

        <!-- Featured Start -->
        <div class="container-fluid pt-5">
            <div class="row px-xl-5 pb-3">
                <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
                    <div class="d-flex align-items-center border mb-4" style="padding: 30px;">
                        <h1 class="text-primary m-0 mr-3"><i class="fa fa-check"></i></h1>
                        <h5 class="font-weight-semi-bold m-0" translate="no">Productos de calidad</h5>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
                    <div class="d-flex align-items-center border mb-4" style="padding: 30px;">
                        <h1 class="text-primary m-0 mr-2"><i class="fa fa-shipping-fast"></i></h1>
                        <h5 class="font-weight-semi-bold m-0" translate="no">Envíos a domicilio</h5>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
                    <div class="d-flex align-items-center border mb-4" style="padding: 30px;">
                        <h1 class="text-primary m-0 mr-3"><i class="fas fa-exchange-alt"></i></h1>
                        <h5 class="font-weight-semi-bold m-0" translate="no">5 días para devoluciones</h5>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
                    <div class="d-flex align-items-center border mb-4" style="padding: 30px;">
                        <h1 class="text-primary m-0 mr-3"><i class="fa fa-phone-volume"></i></h1>
                        <h5 class="font-weight-semi-bold m-0">Soporte</h5>
                    </div>
                </div>
            </div>
        </div>
        <!-- Featured End -->

        <!-- Offer Start -->
        <!-- <div class="container-fluid offer pt-5">
            <div class="row px-xl-5">
                <div class="col-md-6 pb-4">
                    <div class="position-relative bg-secondary text-center text-md-right text-white mb-2 py-5 px-5">
                        <img :src="require('@/assets/plantilla/img/offer-1.png')" alt="">
                        <div class="position-relative" style="z-index: 1;">
                            <h5 class="text-uppercase text-primary mb-3">20% off en todas las ordenes</h5>
                            <h1 class="mb-4 font-weight-semi-bold">Un titulo que vaya</h1>
                            <a href="" class="btn btn-outline-primary py-md-2 px-md-3">Comprar ahora</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pb-4">
                    <div class="position-relative bg-secondary text-center text-md-left text-white mb-2 py-5 px-5">
                        <img :src="require('@/assets/plantilla/img/offer-2.png')" alt="">
                        <div class="position-relative" style="z-index: 1;">
                            <h5 class="text-uppercase text-primary mb-3">Un subtitulo que vaya</h5>
                            <h1 class="mb-4 font-weight-semi-bold">Un titulo que vaya</h1>
                            <a href="" class="btn btn-outline-primary py-md-2 px-md-3">Comprar ahora</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="container-flui bg-secondary" >
            <div class="text-center mb-4 ">
                <h3 class="section-title my-5  px-5"><span class="px-2 bg-secondary text">Nuestros Productos</span></h3>
            </div>
        </div>
        <!-- Offer End -->
        <div class="container-fluid pt-5 ">
            <!-- <div class="text-center mb-4">
                <h3 class="section-title bg-secondary px-5"><span class="px-2">Nuestros Productos</span></h3>
            </div> -->
            <div class="col-12 d-block d-sm-none px-0 mb-4">
                <temp-categories :isInitialOpen="false" />
            </div>
            <temp-products :productos="productos"> </temp-products>
        </div>
        <!-- Footer Start -->
        <temp-footer></temp-footer>
        <!-- Footer End -->


        <!-- Back to Top -->
        <a href="#" class="btn btn-primary back-to-top"><i class="fa fa-angle-double-up"></i></a>
    </div>
</template>

<style scoped src="@/assets/plantilla/style.css"></style>
<style scoped>
@import '@/assets/fontawesome6/css/all.css';
</style>
<script setup>
import TempCarousel from '@/components/template/TempCarousel'
import TempProducts from '@/components/template/TempProducts'
import TempCategories from '@/components/template/TempCategories'
import TempFooter from '@/components/template/TempFooter'
import TempMenu from '@/components/template/TempMenu'
import TempTopBar from '@/components/template/TempTopBar'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.css'
import Axios from 'axios'
import { ref } from 'vue'
import { UseAuthStore } from '@/store/authStore'
const useAuthStore = UseAuthStore();
const productos = ref({})
const imagenesSlider = ref();
const colorPrimary = ref(null)
const colorSecondary = ref(null)
const getColoresPagina = ()=>{
     Axios.get('/configcolorpagina').then((e)=>{
        colorPrimary.value =  e.data.color.primary;
        colorSecondary.value =  e.data.color.secondary;
        // if(!colorSecondary.value)
            console.log(document.querySelector("#siled"))
    })
}
getColoresPagina();
const getProductos =  ()=>{
     Axios.get('/nuestros-productos').then((e)=>{
        productos.value =  e.data.productos;
    })
}
getProductos();
const getImagenesSlider = ()=>{
    Axios.get('imagenes-slider').then((e)=>{
        imagenesSlider.value = e.data.slider;
        imagenesSlider.value.map( elemento => elemento.ruta = elemento.imagen )
    })
}
getImagenesSlider();
</script>
<style scoped>
    :deep .bg-secondary{
        background-color: v-bind(colorSecondary)!important;
    }
    :deep .bg-primary {
        background-color: v-bind(colorPrimary)!important;
    }
    :deep a.bg-primary {
        background-color: v-bind(colorPrimary)!important;
    }
    :deep .bg-primary:hover {
        background-color: v-bind(colorPrimary)!important;
        opacity: 0.9;
    }
    :deep a.bg-primary:hover {
        background-color: v-bind(colorPrimary)!important;
        opacity: 0.9;
    }
    :deep a.bg-primary:focus {
        background-color: v-bind(colorPrimary)!important;
        opacity: 0.9;
    }
    :deep .text-primary {
        color: v-bind(colorPrimary)!important;
    }
    :deep .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link.active{
        opacity: 0.9;
        color: v-bind(colorPrimary)!important;
    }
    :deep .navbar-nav .nav-link.active{
        color: v-bind(colorPrimary)!important;
        opacity: 0.9;
    }

    :deep .linea {
        background: linear-gradient( 45deg, rgba(0, 0, 0, 0) 100px, v-bind(colorPrimary) 100px );
    }
</style>