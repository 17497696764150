<template>
    <div>
        <container-view titulo="Crear Imagenes portada">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-6 form-group mb-3">
                        <!-- <tool-tip :isInvalid="$v.slider.titulo.$invalid && send ? true : false" msj="Debe ingresar un nombre"></tool-tip>                      -->
                        <div class="form-floating">
                            <input type="text" v-model="slider.titulo" id="titulo"  placeholder="Título" class="form-control"/>
                            <label for="email">Título</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group mb-3">
                        <!-- <tool-tip :isInvalid="$v.slider.subtitulo.$invalid && send ? true : false" msj="Debe ingresar un precio"></tool-tip>                      -->
                        <div class="form-floating">
                            <input type="text" v-model="slider.subtitulo" id="subtitulo" placeholder="Sub Título" class="form-control"/>
                            <label for="email">Sub Título</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group mb-3">
                        <!-- <tool-tip :isInvalid="$v.slider.subtitulo.$invalid && send ? true : false" msj="Debe ingresar un precio"></tool-tip>                      -->
                        <div class="form-floating">
                            <input type="text" v-model="slider.url" id="url" placeholder="URL" class="form-control"/>
                            <label for="email">URL</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group mb-3">
                        <!-- <tool-tip :isInvalid="$v.slider.imagen.$invalid && send ? true : false" msj="Debe ingresar una Descripción"></tool-tip>                      -->
                        <div class="col-12 col-sm-12 form-group">                            
                            <input class="form-control" type="file" id="formFileMultiple" @change="addImage">
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="guardar"><i class="fa fa-plus pe-2"></i>Guardar</button>
                </div>
            </template>
        </container-view>
        <container-view titulo="Portadas cargadas">
            <template v-slot:body>
                <div class="row">
                    <div class="col-sm-2 col-6 d-flex contenedor-img mx-3 p-0" v-for="(img, i) in sliders" :key="i">
                        <div class="justify-content-center">
                            <img v-if="img.imagen" :src="rutaBack+'/'+img.imagen" class="imagen">
                            <div class="col-12 botonera-img">
                                <button class="btn btn-default" @click="deleteImage(img)"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
    </div>
</template>
<style scoped>
.imagen{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.contenedor-img{
    position: relative;
    height: 200px;
}
.botonera-img{
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.233);
    position: absolute;
}
</style>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import { ref } from 'vue'
import Alert from '@/components/utils/alert'
import Slider from '@/components/admin/slider/slider'
import Axios from 'axios'
const slider = ref({});
const sliders = ref({});
const rutaBack = Axios.defaults.baseURL.replace("/api", "")
const base64 = (file)=>{
    return new Promise((resolve)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let name = file.name
        reader.onload = function () {
            let file = {
                name: name,
                base64: reader.result
            }
            return resolve(file)
        };

    })
}
const addImage = async (event)=>{
    let files = event.target.files[0];
    console.log(files)
    let result = await base64(files)
    let imagen ={};
    imagen.prev = URL.createObjectURL(files)
    imagen.base64 = result.base64
    imagen.name = result.name
    slider.value.imagen = imagen; 
}
const guardar = async ()=>{
    await Slider.createSlider(slider.value)
    .then(()=>{
        Alert.Success({text: 'Se a guardado con éxito'}).then( () => getAllSliders() )
    })
    .catch( () => Alert.Error({text: 'Se produjo un error al intentar guardar'}) )
}
const getAllSliders = async ()=>{
    await Slider.getSliders().then( e => sliders.value = e.slider )
}
getAllSliders();
const deleteImage = (data)=>{
    Slider.deleteSlider(data)
    .then(()=> Alert.Success({text: 'Se eliminó con éxito'}).then(()=> getAllSliders() ))
    .catch(()=> Alert.Error({text: 'Se produjo un error al intentar eliminar'}) )
}
</script>