import { createApp } from 'vue'
import App from './App.vue'
import router from '@/routes'
import Axios from 'axios'
import { createPinia } from 'pinia'
import { UseAuthStore } from '@/store/authStore'
import { UsePerfilStore } from '@/store/perfilStore'
import { UseCarritoStore } from '@/store/carritoStore'

Axios.defaults.baseURL = process.env.VUE_APP_URL;
const app = createApp(App)
app.use(createPinia())

const useAuthStore = UseAuthStore();
useAuthStore.token = sessionStorage.getItem('token')

const usePerfilStore = UsePerfilStore();    
const useCarritoStore = UseCarritoStore();
useAuthStore.getUserProfile().then(()=>{
    usePerfilStore.getPerfil();
    useCarritoStore.setStorage();
})

useAuthStore.$subscribe(async (mutation, state) => {

    
    if(mutation.storeId == 'auth'){
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+state.token;
        sessionStorage.setItem('token', state.token)
    }
    
}, { detached: true })

app.use(router)
app.mount('#app')