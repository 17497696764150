import { defineStore } from 'pinia'
import Axios from 'axios'
import { UseAuthStore } from '@/store/authStore'
export const UseCarritoStore = defineStore('carrito', {
    state: ()=>({
        storage: []
    }),
    actions: {
        setStorage(){
            this.storage = sessionStorage.getItem('carrito') ? JSON.parse(sessionStorage.getItem('carrito')) : []
        },
        async agregarCarrito(producto, cantidad, color){
            const useAuthStore = UseAuthStore();
            if(!useAuthStore.usuario)
                throw "Debe loguearse para poder realizar la compra";
            if(!color)
                throw "Debe Seleccionar un color.";
                
            let precio = (producto.oferta ? producto.precio_oferta : producto.precio)
            let carrito = {}
            carrito.producto = {
                id: producto.id,
                nombre: producto.nombre,
                cantidad_pedida: cantidad,
                ruta: producto.imagenes.length > 0 ? producto.imagenes[0].ruta : null,
                precio: precio,
                color: color,
                total: (cantidad * precio ).toFixed(2),
                cliente:{
                    id: useAuthStore.usuario.id
                }
            }
            
            if(this.buscarCarritoStorePorColor(carrito)){
                if(this.buscarCarritoStorePorColor(carrito).producto.cantidad_pedida >= await this.getCantidadProductoPorColor(producto, color))
                    throw "No se pudo agregar al carrito, supera nuestro stock disponible"
                
                const getProducto = this.buscarCarritoStorePorColor(carrito).producto
                getProducto.cantidad_pedida = getProducto.cantidad_pedida + carrito.producto.cantidad_pedida
                getProducto.total = getProducto.cantidad_pedida * carrito.producto.precio
                // this.buscarCarritoStorePorColor(carrito).producto = producto
                // this.buscarCarritoStorePorColor(carrito).producto.cantidad_pedida = this.buscarCarritoStorePorColor(carrito).producto.cantidad_pedida + carrito.producto.cantidad_pedida
                // this.buscarCarritoStorePorColor(carrito).producto.total = this.buscarCarritoStorePorColor(carrito).producto.cantidad_pedida * this.buscarCarritoStorePorColor(carrito).producto.precio
            }else{
                this.storage.push(carrito)
            }
            sessionStorage.setItem('carrito', JSON.stringify(this.storage))
            return true
        },
        quitarProducto(index){
            let carrito = JSON.parse(sessionStorage.getItem('carrito'))
            if(carrito.find((e,i)=> i== index)){
                this.storage = carrito.filter(( e, i)=> i != index);
                sessionStorage.setItem('carrito', JSON.stringify(this.storage));
                return true
            }else{
                return false
            }
        },
        buscarCarritoStorePorColor(carrito){
            return this.storage.find((e)=> e.producto.id == carrito.producto.id && e.producto.color == carrito.producto.color)
        },
        buscarCarritoStorePorTalle(carrito){
            return this.storage.find((e)=> e.producto.id == carrito.producto.id && e.producto.talle == carrito.producto.talle)
        },
        getCantidadProductoPorColor(producto, color){
            return new Promise((resolve)=>{
                Axios.get('nuestros-productos/'+producto.id).then((e)=>{
                    let res = e.data.producto.colores_talles.find((e)=> e.color == color).cantidad
                    return resolve(res)
                })
            })
        },
        borrarCarrito(){
            this.$reset();
            sessionStorage.removeItem('carrito')
        }
    },
})