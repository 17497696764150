<template>
    <div>
        <container-view titulo="Tipo de Usuario">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-12 form-group">
                        <tool-tip :isInvalid="validations.perfil_id.$invalid && send ? true : false" msj="Debe ingresar el tipo de usuario"></tool-tip>
                        <div class="form-floating">
                            <select name="" id="perfil" class="form-control" v-model="usuario.perfil_id" :class="validations.perfil_id.$invalid && send ? 'is-invalid' : ''">
                                <option :value="undefined" :selected="usuario.perfil_id == undefined ? true : false">Seleccione el tipo de usuario</option>
                                <option :value="perfil.id" v-for="perfil in perfiles" :key="perfil.id">{{perfil.descripcion}}</option>
                            </select>
                            <label for="perfil">Tipo de usuario</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Datos de usuario" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="validations.apellido.$invalid && send ? true : false" msj="Debe ingresar un Apellido válido"></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="apellido" v-model="usuario.apellido" placeholder="Apellido" class="form-control" :class="validations.apellido.$invalid && send ? 'is-invalid' : ''" />
                            <label for="nombre">Apellido</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="validations.name.$invalid && send ? true : false" msj="Debe ingresar un Nombre válido"></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="nombre" v-model="usuario.name" placeholder="Nombre" class="form-control" :class="validations.name.$invalid && send ? 'is-invalid' : ''" />
                            <label for="nombre">Nombre</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="validations.dni.$invalid && send ? true : false" msj="Debe ingresar un DNI válido"></tool-tip>
                        <div class="form-floating">
                            <input type="number" id="dni" v-model="usuario.dni" placeholder="dni" class="form-control" :class="validations.dni.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="telefono">DNI</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Localizacion" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="validations.provincia.$invalid && send ? true : false" msj="Debe ingresar una Provincia"></tool-tip>
                        <div class="form-floating">
                            <select  class="form-control mb-2" v-model="usuario.provincia" placeholder="Provincia" id="provincia" :class="validations.provincia.$invalid && send ? 'is-invalid' : ''" >
                                <option :value="null" :selected="usuario.provincia == null ? true : false"> - Seleccione provincia - </option>
                                <option v-for="(provincia, i ) in provincias" :key="i" :value="provincia.nombre" :selected="usuario.provincia == provincia.nombre ? true : false">{{provincia.nombre}}</option>
                            </select>
                            <label for="provincia">Provincia</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="validations.localidad.$invalid && send ? true : false" msj="Debe ingresar una Localidad"></tool-tip>
                        <div class="form-floating">
                            <select  class="form-control mb-2" v-model="usuario.localidad" placeholder="Provincia" id="localidad" :class="validations.localidad.$invalid && send ? 'is-invalid' : ''" >
                                <option :value="''" :selected="usuario.localidad == '' ? true : false"> - Seleccione localidad - </option>
                                <template v-if="localidades">
                                    <option v-for="(localidad, i ) in localidades" :key="i" :value="localidad.nombre" :selected="usuario.localidad == localidad.nombre ? true : false">{{localidad.nombre}}</option>
                                </template>
                            </select>
                            <label for="localidad">Localidad</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group my-3">
                        <tool-tip :isInvalid="validations.domicilio.$invalid && send ? true : false" msj="Debe ingresar un Domicilio válido"></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="domicilio" v-model="usuario.domicilio" placeholder="Domicilio" class="form-control" :class="validations.domicilio.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="domicilio">Domicilio</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 form-group my-3">
                        <tool-tip :isInvalid="validations.telefono.$invalid && send ? true : false" msj="Debe ingresar un Teléfono válido"></tool-tip>
                        <div class="form-floating">
                            <input type="tel" id="telefono" v-model="usuario.telefono" placeholder="Telefono" class="form-control" :class="validations.telefono.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="telefono">Telefono</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group my-3">
                        <tool-tip :isInvalid="validations.email.$invalid && send ? true : false" msj="Error en el email ingresado"></tool-tip>
                        <div class="form-floating">
                            <input type="text" id="email" v-model="usuario.email" placeholder="Nombre" class="form-control" :class="validations.email.$invalid && send ? 'is-invalid' : ''" />
                            <label for="email">Email</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-2 form-group my-3">
                        <tool-tip :isInvalid="validations.cod_postal.$invalid && send ? true : false" msj="Por favor, ingrese el codigo Postal."></tool-tip>
                        <div class="form-floating">
                            <input type="text" v-model="usuario.cod_postal" class="form-control" id="cod_postal" placeholder="Dod. Postal" :class="validations.cod_postal.$invalid && send ? 'is-invalid' : ''"/> 
                            <label for="cod_postal">Cod. Postal</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Guardar Datos">
            <template v-slot:body>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="saveData"><i class="fa fa-pencil me-3"></i>Guardar</button>
                </div>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import ToolTip from '@/components/admin/template/ToolTip'
import { ref, watchEffect, computed } from 'vue'
import Perfil from '@/components/admin/perfil/perfil'
import Alert from '@/components/utils/alert'
import User from './user'
import { $v } from './validations'
import Axios from 'axios'
import { useRouter } from 'vue-router'

const router = useRouter();
const usuario = ref({
    provincia: null,
});
const provincias = ref();
const localidades = ref();
const perfiles = ref();
const send = ref(false)

const getPerfiles = async () =>{
    await Perfil.getPerfiles().then( e => perfiles.value = e )
}
getPerfiles();
const validations = $v(usuario.value)

const saveData = async ()=>{
    send.value = true
    if(validations.value.$invalid)
        return false
    await User.createUser(usuario.value)
    .then(()=> {
        Alert.Success({ text: 'Usuario creado con éxito' }).then(()=>{
            router.push({ name: 'ListarUsuarios'})
        })
    })
    .catch(()=> Alert.Error({ html: '<span><b>Error al crear usuario</b></span></br><small>- Verifique que esta dirección de correo no este en uso. -<small>' }) )
}
const getProvincia = ()=>{
    Axios.get("https://api-localidades.mgutierrez.ar/provinces")
    .then((res) => {
        if (res.data) {
            provincias.value = res.data;
            provincias.value.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        }
    }).catch(() => Alert.Error({text: `No se han podido obtener las provincias, intente más tarde.`}) );
}
getProvincia();
const getLocalidades = (provinciaId)=>{
    return Axios.get("https://api-localidades.mgutierrez.ar/localidades?id="+provinciaId)
    .then((res) => {
        if (res.data) {
            let localidades = res.data;
            localidades.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
            return localidades
        }
    })
}
const finProvinciaId = computed(()=>{
    if(provincias.value && usuario.value.provincia){
        let provinciaId = provincias.value.find((e)=> e.nombre == usuario.value.provincia).id
        return provinciaId
    }
    return null
})
watchEffect(async ()=>{
    if(finProvinciaId.value) localidades.value = await getLocalidades(finProvinciaId.value)
    
    if(localidades.value && !localidades.value.find((e)=> e.nombre == usuario.value.localidad)) usuario.value.localidad = ''

    if(!usuario.value.localidad) usuario.value.localidad = ''
})
</script>
