<template>
    <div>
        <container-view titulo="Pagos pendientes">
            <template v-slot:body>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>payment method</th>
                            <th>payment type</th>
                            <th>status</th>
                            <th>status detail</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(pago, i) in pagos" :key="i">
                            <td>{{pago.cliente_nombre}}</td>
                            <td>{{pago.payment_method_id}}</td>
                            <td>{{pago.payment_type_id}}</td>
                            <td>{{pago.status}}</td>
                            <td>{{pago.status_detail}}</td>
                            <td><button class="btn btn-success btn-xs" @click="buscarEstado(pago.id)"><i class="fa-solid fa-arrows-rotate"></i></button></td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import { ref } from 'vue'
import Axios from 'axios'
// import Alert from '@/components/utils/alert'
const pagos = ref();

const getPagos = ()=>{
    Axios.get('pago', {searchStatus: 'pending'}).then((e)=>{
        console.log(e)
        pagos.value = e.data.pagos
    })
}
getPagos();

const buscarEstado = (id)=>{
    // let config = {
    // headers: {'Authorization': 'Bearer APP_USR-3631806230558594-051817-e46f4bb0f0b52149df10e69ef1dc21df-1126097404'},
    // // headers: {'Authorization': 'Bearer TEST-3631806230558594-051817-c46526b3615418c279d748b969e77131-1126097404'},
    //     params: {
    //         external_reference: id
    //     },
    // }
    // Axios.get("https://api.mercadopago.com/v1/payments/search", config)
    // .then((e)=>{
    //     console.log(e)
    // }).catch(()=>{
    //     Alert.Error({html: `<span>Se produjo un error al verificar su pago</span>`})
    // })
    Axios.put('pago/'+id).then((e)=>{
        console.log(e)
        // pagos.value = e.data.pagos
    })
}

</script>