import Axios from 'axios';
export default class Producto {
    static createProducto(producto){
        return new Promise((resolve, reject)=>{
            Axios.post('producto', producto).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static update(producto){
        return new Promise((resolve, reject)=>{
            Axios.put('producto/'+producto.producto.id, producto).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static eliminar(producto){
        return new Promise((resolve, reject)=>{
            Axios.delete('producto/'+producto.id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getProductos(){
        return new Promise((resolve, reject)=>{
            Axios.get('producto').then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static getProducto(id){
        return new Promise((resolve, reject)=>{
            Axios.get('producto/'+id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static deleteImage(id){
        return new Promise((resolve, reject)=>{
            Axios.delete('imagen/'+id).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}