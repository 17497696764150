<template>
    <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item carrusel" :class="index==0 ? 'active' : ''" v-for="(image, index) in images" :key="image.id">
                <img :src="urlBack+'/'+image.ruta" class="d-block img-principal" alt="Galeria de imagenes de producto">
                <img :src="urlBack+'/'+image.ruta" class="d-block img-secundaria" alt="Galeria de imagenes de producto">
                <div class="fondo-oscuro">

                </div>
                <div class="carousel-caption col-12 d-flex flex-column align-items-center justify-content-center" v-if="isDetail">
                    <div class="p-0">
                        <h4 class="text-light font-weight-medium mb-3 text-uppercase titulos-style" v-if="image.subtitulo">{{image.subtitulo}}</h4>
                        <h3 class="display-4 text-white fw-bold mb-4 titulos-style" v-if="image.titulo">{{image.titulo}}</h3>
                        <a :href="image.url" class="btn btn-light py-2 px-3 rounded-0" v-if="image.url">Ver Articulo</a>
                    </div>
                </div>
            </div>
            <div class="carousel-item active carrusel" v-if="images==null || images.length == 0">
                <img :src="require('@/assets/no-image-1.png')" class="d-block img-principal" alt="...">
                <img :src="require('@/assets/no-image-1.png')" class="d-block img-secundaria" alt="...">
            </div>
        </div>
        <a class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </a>
        <a class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </a>
    </div>
</template>
<style scope>
    .carrusel{
        height: 400px;
        position: relative;
    }
    .carrusel .img-principal{
        height: 100%!important;
        width: 100%!important;
        object-fit: contain;
        /* object-fit: scale-down; */
        z-index: 100;
        position: absolute;
    }
    .carrusel .img-secundaria{
        height: 100%!important;
        width: 100%!important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 99;
        filter: blur(79px);
    }
    .carrusel div{
        z-index: 101;
    }
    .carousel-caption{
        left: 0!important;
    }
    .fondo-oscuro{
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: rgb(0 0 0 / 24%);
        position: absolute;
    }
    .titulos-style{
        font-family: "Poppins", sans-serif;
        text-shadow: 1px 1px 2px black;
    }
</style>
<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<script setup>
import { defineProps } from 'vue'
defineProps({
    isDetail: {
        type: Boolean,
        default: true
    },
    images:{
        type: Object,
        default: null
    }
})
const urlBack = process.env.VUE_APP_URL.replace("/api", "")
</script>
