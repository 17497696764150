<template>
    <div class="row px-xl-5 pb-3">
        <div class="col-lg-3 col-md-6 col-sm-12 pb-1" v-for="producto in productos" :key="producto.id">
            <div class="card product-item border-0 mb-4">
                <div class="linea" v-if="producto.oferta">
                    <p class="text-white">Oferta</p>
                </div>
                <div class="card-header product-img position-relative overflow-hidden bg-transparent border p-0 imagenes">
                    <!-- <img class="img-fluid w-100" :src="require(urlBack+'/'+producto.imagenes[0].ruta)" alt=""> -->
                    <img class="img-fluid img-principal" :src="urlBack+'/'+producto.imagenes[0].ruta" alt="" v-if="producto.imagenes.length > 0">
                    <img class="img-fluid img-secundaria" :src="urlBack+'/'+producto.imagenes[0].ruta" alt="" v-if="producto.imagenes.length > 0">
                    <img class="img-fluid img-principal" :src="require('@/assets/no-image-1.png')" alt="" v-if="producto.imagenes.length == 0">
                    <img class="img-fluid img-secundaria" :src="require('@/assets/no-image-1.png')" alt="" v-if="producto.imagenes.length == 0">
                </div>
                <div class="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 class="text-truncate mb-3">{{producto.nombre}}</h6>
                    <div class="d-flex justify-content-center" v-if="producto.oferta">
                        <h6 class="text-muted mr-2"><del>${{producto.precio}}</del></h6>
                        <h6>${{producto.precio_oferta}}</h6>
                    </div>
                    <div class="d-flex justify-content-center" v-else>
                        <h6>${{producto.precio}}</h6>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-between bg-light border">
                    <router-link class="btn btn-sm text-dark p-0" :to="{ name: 'ProductDetail', params: {id:producto.id} }"><i class="fas fa-eye text-primary mr-1"></i>Ver detalle</router-link>
                    <!-- <button class="btn btn-sm text-dark p-0" v-if="useAuthStore.$state.isLoggin && producto.cantidad > 0" @click="agregar(producto)"><i class="fas fa-shopping-cart text-primary mr-1"></i>Agregar al carrito</button> -->
                    <span class="btn btn-sm text-dark p-0" v-if="useAuthStore.$state.isLoggin && producto.cantidad <= 0" title="Sin stock momentaneamente"><del><i class="fas fa-shopping-cart text-primary mr-1"></i>Agregar al carrito</del></span>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<style>
.linea{
    position: absolute;
    right: 0;
    width: 50%;
    height: 80px;
    /* display: block; */
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 100px,
      #e6b0aa 100px
    );
    z-index: 102;
    display: flex;
    align-items: initial;
    justify-content: end;
    padding-right: 3px;
    padding-top: 10px;
    font-weight: 500;
}
</style>
<script setup>
import { UseAuthStore } from '@/store/authStore'
import { defineProps } from 'vue'
// import { UseCarritoStore } from '@/store/carritoStore'
// import Alert from '@/components/utils/alert'
// import sound from '@/components/utils/sound'

defineProps({
    productos: {
        type: Object,
        default: null
    }
})
const urlBack = process.env.VUE_APP_URL.replace("/api", "")
const useAuthStore = UseAuthStore();
// const useCarritoStore = UseCarritoStore();

// const agregar = (data)=>{
//     try{
//         useCarritoStore.agregarCarrito(data, 1).then(()=>{
//             sound.Success();
//             Alert.Success({text: 'El producto fue agregado a su carrito'})
//         }).catch((error)=>{
//             Alert.Error({text: error})
//         })
//     }catch(error){
//         console.log(error)
//         Alert.Error({text: error})
//     }
// }
</script>
