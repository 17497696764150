<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <span class="d-none d-sm-inline">Bienvenido</span> {{UseAuthStore().usuario?.name}}
        </a>
        <button @click="menu" class="navbar-toggler" type="button" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
    </div>
    </nav>
</template>
<script setup>
    import { UseAuthStore } from '@/store/authStore' 
    const menu =()=>{
        let menu = document.querySelector(".add")
        menu.querySelector(".menu-area").classList.toggle("esconder-menu")
        menu.querySelector(".contenido-area").classList.toggle("esconder-menu")
        menu.classList.toggle("adminTemplate2")
        menu.classList.toggle("adminTemplate")
    }
</script>
