<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur | Ver pedido</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá, productos" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar>
            <template v-slot:usuario>
                <span v-if="useAuthStore.$state.usuario">Bienvenido {{useAuthStore.$state.usuario?.name}}</span>
            </template>
        </temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>                    
                        <temp-menu></temp-menu>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Mis Pedidos</h3>
            </div>
        </div>
        <div class="row col-12 col-sm-8 mx-auto d-flex align-items-end">
            <div class="col-12 col-sm-12 col-lg-12">
                <table class="table table-bordered text-center mb-0 tabla-responsiva-detalle-pedido">
                    <thead class="bg-secondary text-dark">
                        <tr>
                            <th>Producto</th>
                            <th>Color</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody class="align-middle">
                        <tr v-for="(pedido, i) in pedidoDetalles.pedido_productos" :key="i">
                            <td class="producto">
                                <b>{{pedido.producto}}</b>
                            </td>
                            <td class="color">
                                <span class="mr-2"><small>Color: </small></span>
                                <b class="px-sm-3 py-sm-1 px-3 py-2" :style="'background-color:'+pedido.color"></b>
                            </td>
                            <td class="precio">
                                <span class="mr-2"><small>Precio: </small></span>
                                <div>
                                    <b>${{pedido.precio}}</b>
                                </div>
                            </td>
                            <td class="cantidad">
                                <span class="mr-2"><small>Cantidad: </small></span>
                                <b>{{pedido.cantidad}}</b>
                            </td>
                            <td class="total">
                                <span class="mr-2"><small>Total: </small></span>
                                <div>
                                    <b>${{pedido.total}}</b>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p v-if="pedidoDetalles.length == 0" class="text-center text-muted mt-4">No a agregado productos a su carrito</p>
            </div>
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<style>
.tabla-responsiva-detalle-pedido span{
    display: none;
}
@media (max-width: 599px) {
    .tabla-responsiva-detalle-pedido tbody{
        border-top: 0px!important;
    }
    .tabla-responsiva-detalle-pedido span{
        display: block;
    }
    .tabla-responsiva-detalle-pedido{
        overflow: scroll;
    }
    .tabla-responsiva-detalle-pedido td{
        border: none!important;
    }
    .tabla-responsiva-detalle-pedido thead{
        display: none;
    }
    .tabla-responsiva-detalle-pedido tbody tr{
        display: grid;
        grid-template-areas: 'producto producto producto producto'
                            'color color color .'
                            'precio precio cantidad cantidad'
                            'total total total total';
        grid-template-columns: 1fr 1fr 1fr 1fr;
        border: 1px solid #dfdfdf!important;
    }
    .tabla-responsiva-detalle-pedido .producto{
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        grid-area: producto;
        padding: 10px 5px!important;
    }
    .tabla-responsiva-detalle-pedido .color{
        grid-area: color;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        padding: 0 5px!important;
    }
    .tabla-responsiva-detalle-pedido .precio{
        grid-area: precio;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        padding: 0 5px!important;
    }
    .tabla-responsiva-detalle-pedido .cantidad{
        grid-area: cantidad;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
        padding: 0 0px!important;
    }
    .tabla-responsiva-detalle-pedido .total{
        padding: 2px 5px!important;
        margin-bottom: 10px;
        grid-area: total;
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        flex-wrap: wrap;
    }

}
</style>
<script setup>
import { useRoute } from 'vue-router'
import Axios from 'axios'
import TempTopBar from '@/components/template/TempTopBar'
import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import { UseAuthStore } from '@/store/authStore'
import { ref } from 'vue';
const useAuthStore = UseAuthStore();
const route = useRoute();
const pedidoDetalles = ref({});
Axios.get('pedido/'+route.params.id).then( e => pedidoDetalles.value = e.data.pedido )
</script>