<template>
<div style="height:100vh">
     <loader-view></loader-view>
     <router-view></router-view>
</div>
</template>

<script setup>
import LoaderView from '@/components/template/Loader'
import { onUnmounted } from 'vue'
import { UsePerfilStore } from '@/store/perfilStore'
const usePerfilStore = UsePerfilStore()
onUnmounted(()=>{
     alert("asd")
     usePerfilStore.$reset();
})
// sessionStorage.setItem("is login", true)
</script>

