<template>
    <div>
        <container-view titulo="Datos del pedido">
            <template v-slot:body>
                <div class="row align-items-end d-flex">
                    <div class="col-12 col-sm-12 col-lg-6">
                        <p>Nombre del cliente: <b class="ps-1">{{pedido.cliente_nombre}}</b></p>
                    </div>
                    <div class="col-12 col-sm-12 col-lg-6">
                        <p>DNI: <b class="ps-1">{{pedido.dni}}</b></p>
                    </div>
                    <div class="col-12 col-sm-12">
                        <p>Domicilio: <b class="ps-1">{{pedido.provincia}}, {{pedido.ciudad}}, {{pedido.domicilio}}</b></p>
                    </div>
                    <div class="col-12 col-sm-12 col-lg-6">
                        <p>Email: <b class="ps-1">{{pedido.email}}</b></p>
                    </div>
                    <div class="col-12 col-sm-12 col-lg-6">
                        <p>Fecha del pedido: <b class="ps-1">{{Fecha.formatDate(pedido.created_at)}}</b></p>
                    </div>
                    <div class="col-12 col-sm-12 col-lg-6">
                        <p>Estado: <b class="ps-1">{{pedido.estado}}</b></p>
                    </div>
                    <div class="col-12 d-flex justify-content-end mt-3">
                        <div class="col-12 col-sm-12 col-lg-6">
                            <div class="bg-success py-3 col-6 text-center text-white h4 rounded">Total: <b class="ps-1">${{pedido.total}}</b></div>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Detalles del pedido">
            <template v-slot:body>
                <div class="row align-items-end">
                    <table class="table tabla-responsiva">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Color</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(producto, i) in pedido.pedido_productos" :key="i">
                                <td class="text-muted producto"><span class="me-3"><small>Producto: </small></span><b>{{producto.producto}}</b></td>
                                <td class="text-muted cantidad"><span class="me-3"><small>Cantidad: </small></span><b>{{producto.cantidad}}</b></td>
                                <td class="text-muted color"><span class="me-3"><small>Color: </small></span><p :style="'background-color:'+producto.color" class="py-3 col-5 col-sm-3 mb-0"></p></td>
                                <td class="text-muted total"><span class="me-3"><small>Total: </small></span><b>${{producto.total}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </container-view>
    </div>
</template>
<style>
    .tabla-responsiva tbody tr span{
        display: none!important
    }
@media (max-width: 599px) {
    .tabla-responsiva tbody tr span{
        display: flex!important
    }
    .tabla-responsiva tbody{
        border-top: none!important
    }
    .tabla-responsiva tr{
        display: grid;
        /* grid-template-columns: 100% 1fr 1fr 1fr; */
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "producto producto"
                            "cantidad colors"
                            "total total";
    }
    .tabla-responsiva thead{
        display: none;
    }
    .producto{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        grid-area: producto;
    }
    .cantidad{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        grid-area: cantidad;
    }
    .color{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        grid-area: colors;
    }
    .total{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        word-break: break-word;
        grid-area: total;
    }
}
</style>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import Pedido from '@/components/admin/pedido/pedido'
import Fecha from '@/components/utils/fecha'
const route = useRoute();
const pedido = ref({
    id: route.params.id
})
const getPedido = async ()=>{
    Pedido.getPedido(pedido.value.id).then((e)=>{
        console.log(e)
        pedido.value = e.pedido
    }).catch((e)=>{
        console.log(e)
    })
}
getPedido();
</script>