<template>
    <div class="col-12 loader" v-if="requestsCount > 0">
        <div class="loader1 pt-4 pb-3 px-5">
            <div class="d-flex justify-content-center text-primary">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                </div>
            </div>
            <p class="">Loading...</p>
        </div>
     </div>
</template>
<script setup>
import Axios from 'axios';
import { ref, onMounted } from 'vue'

const requestsCount = ref(0)
const loader = ()=>{
    Axios.interceptors.request.use((config) => {  
       
        if(config.params){
            if(config.params.select)
                return config;
        }
        requestsCount.value++;
        return config;
    },(error) => {
        requestsCount.value--;
        return Promise.reject(error);
    }
    );
    Axios.interceptors.response.use((response) => {
        if(response.config.params){
            if(response.config.params.select)
                return response;
        }
        requestsCount.value--;
        return response;
    },
    (error) => {
        
        requestsCount.value--;
        return Promise.reject(error);
    }
    );
}
onMounted(()=>{
    loader();
})

</script>
<style scoped>
.loader{
    position: fixed;
    width: 100%!important;
    height: 100%;
    z-index: 200000;
    display: flex;
    background-color: #0000002e;
    top: 0;
}
.loader1{
    width: auto;
    height: auto;
    background-color: white;
    margin: auto;
    border-radius: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loader1 img{
    padding-top: 10%;
    width: 31%;
}
.loader1 p{
    text-align: center;
}
</style>