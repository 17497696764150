<template>
    <div>
        <container-view titulo="Datos de producto" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-6 form-group mb-3">
                        <tool-tip :isInvalid="$v.producto.nombre.$invalid && send ? true : false" msj="Debe ingresar un nombre"></tool-tip>                     
                        <div class="form-floating">
                            <input type="text" v-model="producto.nombre" id="email"  placeholder="Nombre" class="form-control" :class="$v.producto.nombre.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="email">Nombre</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group mb-3">
                        <tool-tip :isInvalid="$v.producto.precio.$invalid && send ? true : false" msj="Debe ingresar un precio"></tool-tip>                     
                        <div class="form-floating">
                            <input type="number" v-model="producto.precio" id="precio" placeholder="Precio" class="form-control" :class="$v.producto.precio.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="email">Precio</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group mb-3">
                        <tool-tip :isInvalid="$v.producto.descripcion.$invalid && send ? true : false" msj="Debe ingresar una Descripción"></tool-tip>                     
                        <div class="form-floating">
                            <input type="text" v-model="producto.descripcion" id="descripcion" placeholder="Descripción" class="form-control" :class="$v.producto.descripcion.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="email">Descripción</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Color y Talle" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end justify-content-between">
                    <div class="col-sm-2 col-12 form-group">
                        <tool-tip :isInvalid="$v.color.$invalid && sendExtras ? true : false" msj="Debe ingresar un color"></tool-tip>
                        <div class="col-12 d-flex">
                            <label class="mb-3 me-3 my-auto" for="">Color: </label>
                            <input type="color" id="color" v-model="color" placeholder="Seleccione el color" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group">                            
                        <div class="form-floating my-3 my-sm-auto">
                            <select name="" id="" class="form-control" v-model="talleSeleccionado">
                                <option :value="null" :selected="true">Sin talle</option>
                                <option :value="talle" v-for="talle in talles" :key="talle.id">{{talle.talle}}</option>
                            </select>
                            <label for="email">Talle</label>
                        </div>
                    </div>
                    <div class="col-sm-3 col-12">
                        <tool-tip :isInvalid="$v.cantidad.$invalid && sendExtras ? true : false" msj="Debe ingresar una cantidad"></tool-tip>
                        <div class="form-floating">
                            <input type="number" class="form-control" placeholder="cantidad" v-model="cantidad" :class="$v.cantidad.$invalid && sendExtras ? 'is-invalid' : ''">
                            <label for="">Cantidad</label>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="col-12">
                            <table class="table text-center">
                                <thead>
                                    <th class="py-2">Color</th>
                                    <th class="py-2">Talle</th>
                                    <th class="py-2">cantidad</th>
                                    <th class="py-2"></th>
                                </thead>
                                <tbody>
                                    <tr v-for="(datosExtra, i) in datosExtras" :key="i">
                                        <td><p class="p-3 col-2 mx-auto" :style="'background-color:'+datosExtra.color"></p></td>
                                        <td>{{datosExtra.talle?.talle}}</td>
                                        <td>{{datosExtra.cantidad}}</td>
                                        <td>
                                            <button class="btn" @click="quitarColorTalle(datosExtra, i)"><i class="fa fa-times text-danger"></i></button>
                                        </td>
                                    </tr>
                                    <tr v-if="datosExtras.length == 0"></tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12" v-if="datosExtras.length == 0">
                            <p class="text-center">Sin datos extras...</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="addColorTalle"><i class="fa fa-plus me-2"></i> Agregar</button>
                </div>
            </template>
        </container-view>
        <container-view titulo="Oferta" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 my-3">
                        <div class="form-check form-switch">
                            <input v-model="producto.oferta" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Oferta</label>
                        </div>
                    </div>
                    <div class="col-12 form-group">                            
                        <div class="form-floating">
                            <input v-model="producto.precio_oferta" :readonly="producto.oferta ? false : true" type="text" id="email"  placeholder="Nombre" class="form-control" />
                            <label for="email">Precio oferta</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Categorias" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="col-12 col-sm-6 form-group">
                        <tool-tip :isInvalid="$v.producto.categoria_id.$invalid && send ? true : false" msj="Debe ingresar una categoria"></tool-tip>                                               
                        <div class="form-floating mb-3 mb-sm-auto">
                            <select name="" id="" class="form-select" v-model="producto.categoria_id" :class="$v.producto.categoria_id.$invalid && send ? 'is-invalid' : ''">
                                <option :value="categoria.id" v-for="categoria in categorias" :key="categoria.id">{{categoria.descripcion}}</option>
                            </select>
                            <label for="email">Categoria</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 form-group">                            
                        <div class="form-floating">
                            <select name="" id="" class="form-select" v-model="producto.subcategoria_id">
                                <option :value="subCategoria.id" v-for="subCategoria in subCategorias" :key="subCategoria.id">{{subCategoria.descripcion}}</option>
                            </select>
                            <label for="email">Sub Categoria</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Marcas" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-item-end">
                    <div class="col-12 col-sm-12 form-group mb-3 text-end">                            
                        <div class="form-floating">
                            <button class="btn btn-primary col-12 col-sm-auto" @click="crearMarca"><i class="fa fa-tags"></i> Nueva Marca</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 form-group">                            
                        <div class="form-floating">
                            <select name="" id="" class="form-select" v-model="producto.marca_id">
                                <option :value="marca.id" v-for="marca in marcas" :key="marca.id">{{marca.marca}}</option>
                            </select>
                            <label for="email">Marca</label>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Imagenes" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12 col-sm-12 form-group">                            
                        <label for="formFileMultiple" class="form-label">Multiple files input example</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple @change="addImage">
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-2 col-6 d-flex contenedor-img mx-3 p-0" v-for="(img, i) in producto.imagenes" :key="i">
                            <div class="justify-content-center">
                                <img :src="img.prev" class="imagen">
                                <div class="col-12 botonera-img">
                                    <button class="btn btn-default" @click="borrarImg(img)"><i class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </container-view>
        <container-view titulo="Guardar producto" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary col-12 col-sm-auto" @click="guardarProducto"><i class="fa fa-plus me-2"></i>Guardar</button>                   
                </div>
            </template>
        </container-view>
    </div>
</template>
<style scoped>
.imagen{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.contenedor-img{
    position: relative;
    height: 200px;
}
.botonera-img{
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.233);
    position: absolute;
}
</style>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import Producto from '@/components/admin/producto/producto'
import Categorias from '@/components/admin/categoria/categoria'
import { ref, watchEffect, watch } from 'vue'
import Alert from '@/components/utils/alert'
import { useRouter } from 'vue-router'
import {$v as validations} from '@/components/admin/producto/validations'
import ToolTip from '@/components/admin/template/ToolTip'
import Marca from '@/components/admin/marca/marca'
import MarcaUtils from '@/components/admin/marca/utils'
import { Talle } from '@/components/admin/talle/talle'
// import Swal from 'sweetalert2'
const router = useRouter();
const producto = ref({
    nombre: null,
    descripcion: null,
    oferta:false,
    precio_oferta: 0.0,
    mostrar:true,
    categoria_id:null,
    subcategoria_id:null,
    marca_id:null,
    imagenes: []
});
const datosExtras = ref([])
const color = ref('#0f0f0f');
const cantidad = ref();
const talleSeleccionado = ref(null)
//--vuelidate
const send = ref(false);
const sendExtras = ref(false);
const $v = validations({producto, color, cantidad});

//---
const guardarProducto = async()=>{
    send.value = true;
    if($v.value.producto.$invalid)
        return false
    if(datosExtras.value.length == 0){
        Alert.Error({ text: 'Por favor, ingresa al menos un color y una cantidad.'})
        return false
    }

    await Producto.createProducto({producto: producto.value, datosExtras: datosExtras.value}).then(()=>{
        Alert.Success({text: 'Producto guardado con éxito'}).then(()=>{
            router.push({ name: 'ListarProductos'})
        })
    }).catch((e)=>{
        Alert.Error({
            html: `<span>Ocurrió un error al guardar producto.</span>
            </br><small> - ${e.response.data.message} -</small>`
        })
    })   
}

/* talle*/
const talles = ref({});
Talle.getTalles().then((e)=>talles.value = e.data.talles)
/*-------*/

const categorias = ref([])
const subCategorias = ref({})
const marcas = ref();
const getMarcas = async()=>{ await Marca.getMarcas().then((e)=>{marcas.value = e.data.marcas})}
getMarcas();

const crearMarca = async ()=>{
    MarcaUtils.ModalCreate().then(()=> getMarcas() )
}

Categorias.getCategorias().then((e)=>categorias.value = e)

const addColorTalle = ()=>{
    sendExtras.value = true;
    if($v.value.color.$invalid)
        return false
    if($v.value.cantidad.$invalid)
        return false
    datosExtras.value.push({
        cantidad: cantidad.value,
        talle: talleSeleccionado.value ?? null,
        color: color.value
    })
}

const base64 = (file)=>{
    return new Promise((resolve)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let name = file.name
        reader.onload = function () {
            let file = {
                name: name,
                base64: reader.result
            }
            return resolve(file)
        };

    })
}
const addImage = (event)=>{
    let files = Array.from(event.target.files);
    files.forEach(async(file)=>{
        let result = await base64(file)
        let imagen ={};
        imagen.prev = URL.createObjectURL(file)
        imagen.base64 = result.base64
        imagen.name = result.name
        producto.value.imagenes.push(imagen);
    })     
}

const borrarImg = (data)=>{
    producto.value.imagenes = producto.value.imagenes.filter((e)=> e.prev != data.prev)
}
const quitarColorTalle = (datosExtra, index) =>{
    datosExtras.value = datosExtras.value.filter(( e, i )=> i != index )
    return true
}
watchEffect(()=>{
  
    if(producto.value.oferta == false){
        producto.value.precio_oferta = null
    }
    if(producto.value.categoria_id){
        // producto.value.subcategoria_id = null
        subCategorias.value = categorias.value.find((e)=> e.id == producto.value.categoria_id).sub_categorias
    }
})
watch(() => producto.value.categoria_id,
    () => {
      producto.value.subcategoria_id = null
    }
);
</script>
