import moment from 'moment';

export default class Fecha{
    static formatDate(value, format){        
        if(!value) return '-'
        
        if(format) return moment.utc(String(value)).format(format)
        
        return moment.utc(String(value)).format('DD/MM/YYYY hh:mm') 
    }
}