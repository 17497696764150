import InicioAdmin from '@/components/admin/InicioAdmin'

import Bienvenida from '@/components/admin/Bienvenida'

import VerUsuario from '@/components/admin/usuario/VerUsuario'
import VerUsuarioAdmin from '@/components/admin/usuario/VerUsuario'
import ListarUsuarios from '@/components/admin/usuario/ListarUsuarios'
import CrearUsuario from '@/components/admin/usuario/CrearUsuario'

import ListarColores from '@/components/admin/color/ListarColores'
import CreateColor from '@/components/admin/color/CreateColor'

import ListarCategorias from '@/components/admin/categoria/ListarCategorias'

import CreateProducto from '@/components/admin/producto/CreateProducto'
import ListarProductos from '@/components/admin/producto/ListarProductos'
import VerProducto from '@/components/admin/producto/VerProducto'


import ListarCliente from '@/components/admin/cliente/ListarCliente'
import VerClienteAdmin from '@/components/admin/cliente/VerCliente'

import CreateMarca from '@/components/admin/marca/CreateMarca'
import ListarMarcas from '@/components/admin/marca/ListarMarcas'
import VerMarca from '@/components/admin/marca/VerMarca'

import VerPedido from '@/components/admin/pedido/VerPedido'
import ListarPedidos from '@/components/admin/pedido/ListarPedidos'

import CreateSlider from '@/components/admin/slider/CreateSlider'

import PagosPendientes from '@/components/admin/pagos/PagosPendientes'

import ConfiguracionPagina from '@/components/admin/configuracion/ConfiguracionPagina'

const bienvenida = [
    {
        path: "/admin",
        name:"Bienvenida",
        component: Bienvenida,
    },
]
const config = [
    {
        path: "/config",
        name:"ConfiguracionPagina",
        component: ConfiguracionPagina,
    },
]
const usuario = [
    {
        path: "/mi-perfil",
        name:"VerUsuario",
        component: VerUsuario,
    },
    {
        path: "/ver-usuario/:id",
        name:"VerUsuarioAdmin",
        component: VerUsuarioAdmin,
    },
    {
        path: "/usuarios",
        name:"ListarUsuarios",
        component: ListarUsuarios,
    },
    {
        path: "/crear-usuario",
        name:"CrearUsuario",
        component: CrearUsuario,
    },
]
const categorias = [
    {
        path: "/categorias",
        name:"ListarCategorias",
        component: ListarCategorias, 
    }
]
const colores = [
    {
        path:"/colores",
        name:"ListarColores",
        component: ListarColores
    },
    {
        path:"/colores/create",
        name:"CrearColor",
        component: CreateColor
    }

]
const marcas = [
    {
        path:"/marca/create",
        name:"CreateMarca",
        component: CreateMarca
    },
    {
        path:"/marca/listar",
        name:"ListarMarcas",
        component: ListarMarcas
    },
    {
        path:"/ver-marca/:id",
        name:"VerMarca",
        component: VerMarca
    }

]
const producto = [
    {
        path:"/crear-producto",
        name:"CreateProducto",
        component: CreateProducto
    },
    {
        path:"/listar-productos",
        name:"ListarProductos",
        component: ListarProductos
    },
    {
        path:"/ver-productos/:id",
        name:"VerProducto",
        component: VerProducto
    }
]
const cliente = [
    {
        path: "/listar-clientes",
        name:"ListarCliente",
        component: ListarCliente,
    },
    {
        path: "/ver-cliente/:id",
        name:"VerClienteAdmin",
        component: VerClienteAdmin,
    }
]
const pedido = [
    {
        path: "/ver-pedido/:id",
        name: "VerPedido",
        component: VerPedido,
    },
    {
       path: "/listado-pedidos",
        name: "ListarPedidos",
        component: ListarPedidos,
    }
]
const pagos = [
    {
        path: "/pagos-pendientes",
        name: "PagosPendientes",
        component: PagosPendientes,
    }
]
const slider = [
    {
        path: "/crear-slider",
        name: "CreateSlider",
        component: CreateSlider,
    }
]
export const AdminRoutes = [
    {
        path: "/admin",
        name:"InicioAdmin",
        component: InicioAdmin,
        redirect: {name: 'Bienvenida'},
        meta: {
            private:true,
            admin: true,
        },
        children:[
            ...categorias,
            ...bienvenida,
            ...usuario,
            ...cliente,
            ...colores,
            ...producto,
            ...marcas,
            ...pedido,
            ...pagos,
            ...slider,
            ...config
        ]
    },
]