<template>
    <div>
        <container-view titulo="Lista de Colores" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row align-items-end">
                    <div class="form-group col-sm-10 col-9">     
                        <tool-tip :isInvalid="$v.nombre.$invalid && send ? true : false" msj="Debe ingresar un nombre de color"></tool-tip>                      
                        <div class="form-floating">
                            <input type="text" id="nombre" v-model="color.nombre" placeholder="Nombre" class="form-control"  :class="$v.nombre.$invalid && send ? 'is-invalid' : ''"/>
                            <label for="email">Nombre del color</label>
                        </div>
                    </div>
                    <div class="col-sm-2 col-3 form-group">
                        <input type="color" id="color" v-model="color.codigo" placeholder="Seleccione el color" class="" />
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary col-sm-auto col-12" :onClick="guardarColor"><i class="fa fa-plus pe-2"></i> Guardar</button>
                </div>
            </template>
        </container-view>
    </div>
</template>
<style scoped>
#color{
    height: 52px;
}
</style>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import Color from '@/components/admin/color/color'
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ToolTip from '@/components/admin/template/ToolTip'
import Alert from '@/components/utils/alert'
const router = useRouter();
const color = ref({
    codigo: "#ffffff"
});
const rules = {
    nombre: { required },
}
const send = ref(false)
const $v = useVuelidate(rules, color);

const guardarColor = async()=>{
    send.value = true;
    if($v.value.$invalid)
        return false
    await Color.createColor(color.value).then(()=>{
        Alert.Success({text: 'Color guardado con éxito'}).then(()=>{
            router.push({ name: 'ListarColores' })
        })
    }).catch(()=>{
        Alert.Error({text: 'Ocurrió un error al guardar el color'})
    })
}



// usuario = useAuthStore.usuario
</script>
