<template>
    <div>
        <a class="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100" data-bs-toggle="collapse" href="#navbar-vertical" role="button" aria-expanded="false" aria-controls="navbar-vertical" style="height: 65px; margin-top: -1px; padding: 0 30px;">
            <h6 class="m-0">Categorías</h6>
            <i class="fa fa-angle-down text-dark"></i>
        </a>
        <nav data-bs-target="#navbar-vertical" :class="isInitialOpen ? 'show' : ''" class="bg-white navbar collapse navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 border-bottom-0 position-absolute col-12 position-sm-relative" id="navbar-vertical">
            <div class="navbar-nav w-100 scroll-style" style="height: 410px">
               <template v-for="(categoria) in categorias" :key="categoria.id">
                    <div class="nav-item dropdown" v-if="categoria.sub_categorias.length > 0" >
                        
                        <a class="nav-link " data-bs-toggle="dropdown" :href="'#a'+categoria.id" role="button" aria-expanded="false" >
                            <router-link  :to="{name:'ListProductos'}">
                                {{categoria.descripcion}} 
                            </router-link>
                            <i class="fa fa-angle-down float-right mt-1"></i>
                        </a>
                        <div :data-bs-target="'#a'+categoria.id" class="bg-white collapse dropdown-menu position-absolute bg-secondary border-0 rounded-0 w-100 m-0" :id="'a'+categoria.id">
                            <router-link :to="{name:'ListProductos', params:{categoria:categoria.descripcion, subCategoria:subCategoria.descripcion}}" class="dropdown-item" v-for="(subCategoria) in categoria.sub_categorias" :key="subCategoria.id">{{subCategoria.descripcion}}</router-link>
                            <router-link :to="{name:'ListProductos', params:{categoria: categoria.descripcion}}" class="dropdown-item">Todos</router-link>
                        </div>
                    </div>
                
                <router-link :to="{name:'ListProductos', params:{categoria:categoria.descripcion}, }" class="nav-item nav-link" v-else>{{categoria.descripcion}}</router-link>
               </template> 
            
            </div>
        </nav>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import { defineProps, ref } from 'vue'
import axios from 'axios'
import Alert from '../utils/alert';


defineProps({
  isInitialOpen: {
      type: Boolean,
      default: true
  }
})
const categorias = ref([]);

const getCategorias = ()=>{
    axios.get("/get-categorias")
    .then((res) => {
        if (res.data) {
            categorias.value = res.data.categorias;
            categorias.value.sort(function (a, b) {
                if (a.descripcion > b.descripcion) {
                    return 1;
                }
                if (a.descripcion < b.descripcion) {
                    return -1;
                }
                return 0;
            });
                    
        }
    }).catch(() => {
        Alert.Error({text: `No se han podido obtener las categorías, intente más tarde.`});
    });
}
getCategorias();
</script>