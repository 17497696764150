<template>
    <div>
        <Teleport to="head">
            <title>Tienda | OnlineSur | Productos</title>
            <meta name="description" content="Tienda online. Encontra lo que buscás, tenemos todas las variedades de artículos. Realizamos envíos a domicilio." />
            <meta name="keywords" content="tienda online, encontra lo que buscás, variedades, artículos, envíos a domicilio, santa rosa, la pampa, ecommerce, tienda, comprá, productos" />
            <meta property="og:type" content="website">
        </Teleport>
        <temp-top-bar :search="params.search">            
        </temp-top-bar>
        <div class="container-fluid">
            <div class="row border-top px-xl-5">
                <div class="col-3">
                    <div class="col-12 d-none d-lg-block" style="position:absolute">
                        <temp-categories :isInitialOpen="false"></temp-categories>
                    </div>
                </div>
                <div class="col-lg-9">
                    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                        <a href="" class="text-decoration-none d-block d-lg-none">
                            <h1 class="m-0 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>                    
                        <temp-menu></temp-menu>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-secondary mb-3">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 150px">
                <h3 class="font-weight-semi-bold text-uppercase mb-3">Nuestra tienda</h3>
            </div>
        </div>
        <div class="col-12 d-block d-sm-none px-0 mb-4">
            <temp-categories :isInitialOpen="false" />
        </div>
        <div class="container-fluid row">
            <div class="col-lg-3  col-xs-12 align-items-center justify-content-center mt-2" >           
                <div class="col-lg-12 col-sm-12 col-xs-12 form-floating mt-5" v-if="!params.orderBy">
                    <select class="form-select  col-xs-12" style="max-height:45px;" id="orderBy"  v-model="params.orderBy" aria-label="Seleccione" >
                        
                        <option value="Mayor precio">Mayor precio</option>
                        <option value="Menor precio">Menor precio</option>
                        <option value="Ordenar de la A - Z">Ordenar de la A - Z</option>
                        <option value="Ordenar de la Z - A">Ordenar de la Z - A</option>
                    </select><label for="orderBy" class="ml-2">Ordenar por: </label>
                </div>            
                <div class="col-lg-12 col-sm-12 col-xs-12  form-floating mt-3" v-if="!params.filterMarca">
    
                    <select class="form-select col-sm-12 col-xs-12"  id="filtro" aria-label="Seleccione" v-model="params.filterMarca"  style="max-height:45px;" >
                        <option></option>
                        <option :value="marca.id" v-for="(marca) in marcas" :key="marca.id"> {{marca.marca}}</option>
                    </select>
                    <label for="filtro" class="ml-2">Seleccionar marcas: </label>
    
                </div>
                <div class="container-fluid  mt-3 no-wrap">
                    <div class="col-12 mb-2">

                        <span class=" text-muted" v-if="params.categoria || params.subCategoria || params.orderBy || params.filterMarca">Filtros: </span>
                    </div>
                    <div class="col-12 ml-3">
                        <button class="btn btn-primary rounded btn-sm  my-1" @Click="quitarCategoria" v-if="params.categoria">{{params.categoria}} &times;</button>
                    </div>
                    <div class="col-12 ml-3">
                        <button class="btn btn-primary rounded btn-sm  my-1" @Click="quitarSubCategoria" v-if="params.subCategoria">{{params.subCategoria}} &times;</button>
                    </div>
                    <div class="col-12 ml-3">
                        <button class="btn btn-primary rounded btn-sm  my-1" @click="quitarFilterMarca" v-if="params.filterMarca">{{marcas.find(m=>m.id == params.filterMarca).marca}}  &times;</button>

                    </div>
                    <div class="col-12 ml-3">
                        <button class="btn btn-primary rounded btn-sm my-1" v-if="params.orderBy" @click="quitarOrderBy">{{params.orderBy}}  &times;</button>
                    </div>
        
    
                </div>
                <div v-if="params.search" class="col-12 text-center mt-2">
                    <span class="mr-2">Resultados de busqueda para: </span>
                    <button class="btn btn-primary rounded btn-sm" @click="quitarBusqueda" >{{params.search}} &times;</button>
                </div>
            </div>
    
            <div class="col-lg-9 col-xs-12 pt-5">
               <temp-products :productos="productos"> </temp-products>
            </div>
            
        </div>
        <temp-footer></temp-footer>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>
<script setup>
import TempTopBar from '@/components/template/TempTopBar'
import TempFooter from '@/components/template/TempFooter'
import TempCategories from '@/components/template/TempCategories'
import TempMenu from '@/components/template/TempMenu'
import TempProducts from '@/components/template/TempProducts'
import Axios from 'axios'
import {  useRoute, useRouter } from 'vue-router'
import {  ref, onMounted, watch, reactive } from 'vue'




const router = useRouter();
const route = useRoute();
const params = reactive({
    categoria:'',
    subCategoria:'',
    search:'',
    orderBy:'',
    filterMarca:''
})

const marcas = ref([])
const productos = ref({})
const getProductos = async ()=>{
    console.log('se ejecuta el getProducts', params)
    Axios.get('/nuestros-productos',{params:params}).then((e)=>{
        
        productos.value = e.data.productos;
        console.log(e.data.marcas)
        
        marcas.value = e.data.marcas.filter(m=>{
            let busqueda = e.data.productos.find(p=>p.marca_id== m.id)
            if(busqueda.marca_id){
                return true
            }else{
                return false
            }
        });
        console.log('termino filter', marcas.value)
    }).catch(err=>{
        console.log('errrrorr de obtener productos', err)
    })
}
const quitarCategoria = ()=>{
    if(params.search != '')
    router.push({name:'ListProductos', query:{search:params.search}})
    else
    router.push({name:'ListProductos'});
    params.categoria=''
    params.subCategoria=''
    
}

const quitarSubCategoria = ()=>{
    router.push({name:'ListProductos', query:{search:params.search}})
    params.subCategoria = ''
}
const quitarFilterMarca = ()=>{
    params.filterMarca = ''
}
const quitarOrderBy = ()=>{
    params.orderBy = ''
}
const quitarBusqueda = ()=>{
    params.search = ''
    router.push({name:'ListProductos', query:{search:params.search}})
}
   onMounted(()=>{
       route.params.categoria ? params.categoria = route.params.categoria : '';
       route.params.subCategoria ? params.subCategoria = route.params.subCategoria : '';
       route.query.search ? params.search = route.query.search : ''
   })
   router.beforeResolve(to=>{
       console.log('esto tiene to',to)
       if(to.name == 'ListProductos'){
           to.params.categoria ? params.categoria = to.params.categoria : '';
           to.params.subCategoria ? params.subCategoria = to.params.subCategoria : '';
           to.query.search ? params.search = to.query.search : '';
       }
       
       console.log('cambia el params', params)
   })
    
    getProductos();
    watch(params, ()=>{
        
        
        getProductos()
    })


</script>