<template>
    <div class="container-fluid bg-secondary text-dark mt-5 pt-5">
        <div class="row px-xl-5 pt-5 justify-content-sm-between d-flex">
            <div class="col-lg-5 col-md-12 mb-5 pr-3 pr-xl-5">
                <a href="" class="text-decoration-none">
                    <h1 class="mb-4 display-5 font-weight-semi-bold"><span class="text-primary font-weight-bold border border-white px-3 mr-1"><i class="fa-brands fa-shopify"></i></span>OnlineSur</h1>
                </a>
                <p>Venta de articulos técnologicos. Contamos con alto stock.</p>
                <p class="mb-2"><i class="fa fa-map-marker-alt text-primary mr-3"></i>Santa Rosa, La Pampa</p>
                <p class="mb-2"><i class="fa fa-envelope text-primary mr-3"></i>admin@onlinesur.com.ar</p>
                <p class="mb-0"><i class="fa fa-phone-alt text-primary mr-3"></i>+012 345 67890</p>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <h5 class="font-weight-bold text-dark mb-4">Contactanos</h5>
                        
                            <div class="form-group">
                                <input type="text" class="form-control border-0 py-4" v-model="contacto.nombre" placeholder="Ingrese su nombre" required="required" />
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control border-0 py-4" v-model="contacto.email" placeholder="Ingrese su email"
                                    required="required" />
                            </div>
                            <div class="form-group">
                                <textarea name="texto" v-model="contacto.texto" class="form-control border-0 py-4" id="" cols="30" rows="3"></textarea>
                            </div>
                            <VueRecaptcha v-if="!verificado" :sitekey="sitekey"  @verify="verifyMethod">
                                
                            </VueRecaptcha>
                            <div class="mt-3" v-else>
                                <button class="btn btn-primary btn-block border-0 py-3" @click="enviarmsj">Enviar</button>
                            </div>
                    
                    </div>
                </div>
            </div>
        </div>
        <div class="row border-top border-light mx-xl-5 py-4">
            <div class="col-md-6 px-xl-0">
                <p class="mb-md-0 text-center text-md-left text-dark">
                    &copy; <a class="text-dark font-weight-semi-bold" href="#">PampaDev</a>. All Rights Reserved.
                </p>
            </div>
            <div class="col-md-6 px-xl-0 text-center text-md-right">
                <img class="img-fluid" style="height: 65px;" :src="require('@/assets/plantilla/img/logoMP.png')" alt="">
            </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/plantilla/style.css"></style>

<script setup>
import { reactive, ref } from '@vue/reactivity';
import axios from 'axios'

import {VueRecaptcha} from 'vue-recaptcha';
import Alerta from '../utils/alert';

const sitekey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
const verificado = ref(false)
const contacto = reactive({
    nombre:'',
    email:'',
    texto:''
})
const enviarmsj = ()=>{
    axios.post('/mail', {params: contacto }).then(()=>{
        Alerta.Success({ text: 'Mensaje enviado con éxito.'})
        contacto.nombre = ''
        contacto.email = ''
        contacto.texto = ''
    }).catch(()=>{
        Alerta.Error({ text: 'Ocurrió un error al enviar mensaje. Por favor intente mas tarde.'})
    })
}
const verifyMethod =()=>{
    console.log('verificado')
    verificado.value = true
    

}

</script>