<template>
    <div>
        <container-view titulo="Crear marca" classCard="bg-white" :isCard="true">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12 form-group"> 
                        <tool-tip :isInvalid="$v.marca.$invalid && send ? true : false" msj="Debe ingresar un nombre de marca"></tool-tip>   
                        <div class="form-floating">                            
                            <input v-model="marca.marca" type="text" class="form-control" id="marca" placeholder="Ingrese marca" :class="$v.marca.$invalid && send ? 'is-invalid' : ''">
                            <label for="marca" class="form-label">Ingrese marca</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="createMarca"><i class="fa fa-plus me-2"></i>Guardar</button>
                </div>
            </template>
        </container-view>
    </div>
</template>
<script setup>
import ContainerView from '@/components/admin/template/Container'
import Marca from './marca';
import { ref } from 'vue';
import ToolTip from '@/components/admin/template/ToolTip';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import Alert from '@/components/utils/alert'

const router = useRouter();
const marca = ref({});

//--vuelidate
const rules = {
    marca: {required }
}
const send = ref(false);
const $v = useVuelidate(rules, marca);
//---

const createMarca = async()=>{
    send.value = true;
    if($v.value.$invalid)
        return false
    await Marca.Create(marca.value).then(()=>{
        Alert.Success({text: 'Marca guardada con éxito'}).then(()=>{
            router.push({ name: 'ListarMarcas'})
        })
    }).catch((e)=>{
        Alert.Error({
            html: `<span>Ocurrió un error al guardar producto.</span>
            </br><small> - ${e.response.data.message} -</small>`
        })
    })
}
</script>